import React, { useMemo }  from "react";
import { Button, Form, Image, InputGroup, Stack } from "react-bootstrap/esm";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";

import { useIndexVaultContext } from "../../../contexts/indexVaultContext";
import { IndexMetadata } from "../../../constants/indexes";
import { formatUnits } from "viem";
import { isNumbersOnly } from "../../../utils/formUtils";
import { useSupportedIndexesBalance } from "../../../hooks/indexes";


type props = {
  controlId: string;
  value: string;
  title: string;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (event: string) => void;
  onSetMaxAmount: (amount: bigint) => void;
  showMax?: boolean;
  disabled?: boolean;
}

export const IndexInput = ({
  controlId,
  value,
  title,
  onFocus,
  onBlur,
  onChange,
  onSetMaxAmount,
  showMax,
  disabled,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { data: balances } = useSupportedIndexesBalance();
  const { selectedIndex, currentUserPocketSnapshot, currentVaultSnapshot } = useIndexVaultContext();
  const currentIndexMetadata = IndexMetadata[selectedIndex];

  const { maxValue, maxValueBI, valueUsd } = useMemo(() => {
    if (!currentUserPocketSnapshot || !currentVaultSnapshot || !balances) return { maxValue: "0", maxValueBI: 0n, valueUsd: 0 };

    const indexBalances = balances[selectedIndex]
    let valueUsd = 0;
    if (isNumbersOnly(value) && value !== "") { 
      valueUsd = parseFloat(value) * parseFloat(formatUnits(currentVaultSnapshot.indexPrice, currentIndexMetadata.decimals));
    }

    const mintedAmount = parseFloat(formatUnits(currentUserPocketSnapshot.mintedAmount, currentIndexMetadata.decimals));
    return {
      maxValue: indexBalances.balanceBI < currentUserPocketSnapshot.mintedAmount && !showMax
        ? indexBalances.balance.toFixed(4)
        : mintedAmount.toFixed(4),
      maxValueBI: indexBalances.balanceBI < currentUserPocketSnapshot.mintedAmount && !showMax
        ? indexBalances.balanceBI
        : currentUserPocketSnapshot.mintedAmount,
      valueUsd,
    };
  },
    // eslint-disable-next-line
    [value, currentUserPocketSnapshot, balances]
  );

  return (
    <Form.Group className="asset-input-group" style={{ marginTop: "0.5rem", marginBottom: "0.1rem" }}>
      <div className="asset-input-top mb-1">
        <h6>{title}</h6>
      </div>
      <InputGroup className="input-collateral">
        <Stack direction="horizontal" className="w-100">
          <Stack direction="vertical" className="justify-content-between" style={{ width: "55%" }}>
            <Form.Control
              id={controlId}
              placeholder="0"
              className="balance-input"
              value={value}
              type={isMobile ? "number" : "text"}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
              }
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
              disabled={disabled}
              style={{ position: "relative", zIndex: 1 }}
            />
            <NumericFormat
              className="balance-usd text-muted"
              value={valueUsd.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              suffix=" USD"
            />
          </Stack> 
          <div className="asset-info" style={{ width: "45%" }}>
            <div className="asset-info2">
              <Image src={currentIndexMetadata.icon} height={26} width={26} />
            </div>
            <div className="asset-balance w-100" style={{ marginTop: "0.2rem" }}>
              <span className="title">
                {!showMax ? t("minted") : t("available")}
              </span>
              <span className="balance me-0">{maxValue}</span>
              {showMax && (
                <Button
                  variant="secondary"
                  className="small ms-1"
                  onClick={() => onSetMaxAmount(maxValueBI || 0n)}
                >
                  {t("max")}
                </Button>
              )}
            </div>
          </div>
        </Stack>
      </InputGroup>
    </Form.Group>
  );    
}
