
import React from "react"
import ReactGA from "react-ga4";
import Indexes from "../../components/Vaults";
import { IndexVaultProvider } from "../../contexts/indexVaultContext";


const IndexVaultPage = () => {
  ReactGA.send({ hitType: "pageview", page: "/indexes", title: "Indexes" });

  return (
    <IndexVaultProvider>
      <Indexes />
    </IndexVaultProvider>
  )
};

export default IndexVaultPage;
