import { Big6Math, HermesClient, PositionSide, SupportedChainId as PerennialSupportedChainIdType } from '@perennial/sdk';
import { Address, createPublicClient, http } from "viem"
import { createConfig } from "wagmi"
import {
  arbitrum,
  arbitrumSepolia,
  aurora,
  avalanche,
  base,
  baseSepolia,
  blast,
  bsc,
  boba,
  celo,
  gnosis,
  fantom,
  fraxtal,
  fuse,
  linea,
  mainnet,
  mantle,
  metis,
  mode,
  moonriver,
  moonbeam,
  optimism,
  polygon,
  polygonZkEvm,
  rootstock,
  scroll,
  seiDevnet,
  sepolia,
  zkSync
} from "viem/chains"
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  trustWallet,
  okxWallet
} from "@rainbow-me/rainbowkit/wallets"


export enum CryptexProducts {
  Governance = "GOVERNANCE",
  Indexes = "INDEXES",
  Perpetuals = "PERPETUALS",
  PerpetualsV1 = "PERPETUALS_V1",
  V1 = "V1",
  Spot = "SPOT",
}

export const DefaultChain = arbitrum;

export const SupportedChainIds = [mainnet.id, sepolia.id, arbitrum.id, arbitrumSepolia.id, base.id, baseSepolia.id]

export const chainIdToChainMap = {
  [mainnet.id]: mainnet,
  [sepolia.id]: sepolia,
  [arbitrum.id]: arbitrum,
  [arbitrumSepolia.id]: arbitrumSepolia,
  [base.id]: base,
  [baseSepolia.id]: baseSepolia,
}

export type SupportedChainIdType = (typeof SupportedChainIds)[number];

export const isSupportedChain = (chainId: number): boolean => {
  const chainsIds = SupportedChainIds as Array<number>
  return chainsIds.includes(chainId)
}

export const PerpetualsGraphUrls: { [chainId in PerennialSupportedChainIdType]: string } = {
  [arbitrum.id]: "https://subgraph.satsuma-prod.com/3f60a9e39458/equilibria/perennial-v2-arbitrum-new/api", // process.env.REACT_APP_GRAPH_URL_ARBITRUM ?? "",
  [arbitrumSepolia.id]: "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/perennial-v2-arbitrumSepolia-new/api", //process.env.REACT_APP_GRAPH_URL_ARBITRUM_SEPOLIA ?? "",
};

export const IndexesGraphUrls: { [chainId in SupportedChainIdType]: string } = {
  [mainnet.id]: "",
  [sepolia.id]: "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/cryptex-base/api",
  [arbitrum.id]: "",
  [arbitrumSepolia.id]: "",
  [base.id]: "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/cryptex-base/api",
  [baseSepolia.id]: "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/cryptex-base/api",
}

export const V1GraphUrls: { [chainId in SupportedChainIdType]: string } = {
  [mainnet.id]: process.env.REACT_APP_V1_GRAPH_URL_MAINNET ?? "",
  [sepolia.id]: process.env.REACT_APP_V1_GRAPH_URL_SEPOLIA ?? "",
  [arbitrum.id]: process.env.REACT_APP_V1_GRAPH_URL_ARBITRUM ?? "",
  [arbitrumSepolia.id]: process.env.REACT_APP_V1_GRAPH_URL_ARBITRUM_SEPOLIA ?? "",
  [base.id]: "",
  [baseSepolia.id]: "",
};

export const FuulGraphUrl = "https://subgraph.satsuma-prod.com/64a0e71a7397/fuul-team--611570/base/api"

export const interfaceFeeBps: {
  [chainId in PerennialSupportedChainIdType]: { feeAmount: { [key in PositionSide]: bigint }; feeRecipientAddress: Address }
} = {
  [arbitrum.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  },
  [arbitrumSepolia.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n,
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  },
}

const WalletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_ID || "";

export const AlchemyKey = process.env.REACT_APP_ALCHEMY_KEY || ""
export const AlchemySepoliaKey = process.env.REACT_APP_ALCHEMY_KEY || ""
export const AlchemyArbitrumKey = process.env.REACT_APP_ALCHEMY_ARBITRUM_KEY || ""
export const AlchemyArbitrumSepoliaKey = process.env.REACT_APP_ALCHEMY_ARBITRUM_KEY_SEPOLIA || ""
export const AlchemyBaseKey = process.env.REACT_APP_ALCHEMY_BASE_KEY || ""
export const AlchemyBaseSepoliaKey = process.env.REACT_APP_ALCHEMY_BASE_KEY_SEPOLIA || ""


export const rpcUrls: { [chainId in SupportedChainIdType]: string } = {
  [arbitrum.id]: `https://arb-mainnet.g.alchemy.com/v2/${AlchemyArbitrumKey}`,
  [arbitrumSepolia.id]: `https://arb-sepolia.g.alchemy.com/v2/${AlchemyArbitrumSepoliaKey}`,
  [base.id]: `https://base-mainnet.g.alchemy.com/v2/${AlchemyBaseKey}`,
  [baseSepolia.id]: `https://base-sepolia.g.alchemy.com/v2/${AlchemyBaseSepoliaKey}`,
  [mainnet.id]: `https://eth-mainnet.g.alchemy.com/v2/${AlchemyKey}`,
  [sepolia.id]: `https://eth-sepolia.g.alchemy.com/v2/${AlchemySepoliaKey}`,
}

export const getPublicClient = (chainId: SupportedChainIdType) => { 
  return createPublicClient({
    batch: {
      multicall: true,
    },
    chain: chainIdToChainMap[chainId],
    transport: http(rpcUrls[chainId])
  })
}

const connectors = connectorsForWallets([{
    groupName: "Recommended",
    wallets: [metaMaskWallet, walletConnectWallet, rainbowWallet, coinbaseWallet, okxWallet, trustWallet],
  }],
  {
    appName: 'Cryptex Finance',
    projectId: WalletConnectProjectId,
  }
);

export const wagmiConfig = createConfig({
  ssr: false,
  chains: [
    mainnet,
    arbitrum, 
    base,
    optimism,
    polygon,
    zkSync,
    avalanche,
    bsc,
    blast,
    mantle,
    polygonZkEvm,
    scroll,
    aurora,
    boba,
    celo,
    gnosis,
    fantom,
    fraxtal,
    fuse,
    linea,
    metis,
    mode,
    moonriver,
    moonbeam,
    rootstock,
    seiDevnet,
    sepolia,
    arbitrumSepolia,
    baseSepolia,
  ],
  transports: {
    [arbitrum.id]: http(rpcUrls[arbitrum.id]),
    [arbitrumSepolia.id]: http(rpcUrls[arbitrumSepolia.id]),
    [base.id]: http(rpcUrls[base.id]),
    [baseSepolia.id]: http(rpcUrls[baseSepolia.id]),
    [mainnet.id]: http(rpcUrls[mainnet.id]),
    [sepolia.id]: http(rpcUrls[sepolia.id]),
    [optimism.id]: http(),
    [polygon.id]: http(),
    [zkSync.id]: http(),
    [avalanche.id]: http(),
    [bsc.id]: http(),
    [blast.id]: http(),
    [mantle.id]: http(),
    [polygonZkEvm.id]: http(),
    [scroll.id]: http(),
    [aurora.id]: http(),
    [boba.id]: http(),
    [celo.id]: http(),
    [gnosis.id]: http(),
    [fantom.id]: http(),
    [fraxtal.id]: http(),
    [fuse.id]: http(),
    [linea.id]: http(),
    [metis.id]: http(),
    [mode.id]: http(),
    [moonriver.id]: http(),
    [moonbeam.id]: http(),
    [rootstock.id]: http(),
    [seiDevnet.id]: http(),
  },
  connectors,
  batch: {
    multicall: true,
  },
})

export const PythMainnetUrl = "https://hermes.pyth.network/"
export const PythTestnetUrl = "https://hermes.pyth.network/"
export const CryptexPricesUrl = "https://api.perps.cryptex.finance"
export const CryptexPriceFeedUrl = 'https://api.perps.cryptex.finance/price-feed'
// export const CryptexPriceFeedUrl = 'https://perps-a701303cff4d.herokuapp.com/price-feed'

const PythBenchmarkUrlVersion = 'v1'
const PythBenchmarkUrl = "benchmarks.pyth.network" //process.env.REACT_APP_PUBLIC_PYTH_DATAFEED_URL
export const PythDataFeedUrl = `https://${PythBenchmarkUrl}/${PythBenchmarkUrlVersion}/shims/tradingview`
export const PythPriceFeedUrl = `https://${PythBenchmarkUrl}/${PythBenchmarkUrlVersion}/price_feeds`

export const BackupPythClient = new HermesClient(
  `${typeof window !== 'undefined' ? window.location.origin : 'https://app.perennial.finance'}/api/pyth`,
  {
    timeout: 30000,
  },
)

export const metamaskTxRejectedError = "user rejected"

export const BetaWhitelist = [
  "0xd4fa23307a181B9ca567886eB5bCd5c8f8f8bB3E",
  "0xF6a16a48099497C59e8abEAa37Bb37B2F9B793d4",
  "0xcF3E2Ee2b0cD1194ba33cD1c7d9614DC635a1714",
  "0xa9c8CD8B3E6E24446D7dFDFd63563FA50852ec3c",
  "0xD43Ca531907f8C8dBb3272Cc2EF321cC572E56E3",
  "0xF211d99364f899acC598F46d1f1456c80B9F0586",
  // users
  "0x4717f20F534C1732a2F987a126181eeF5413Cad3",
  "0x72a5b945E2C722C76B4AB6E746963d064d9e60C7",
  "0x056EA9bcABc431537e09eA4Ee37125AecBB61B64",
  "0xF7864980c4B306fcEd1E039F3499ddD1B6289D63",
  "0x0c7841f7A3716833a8e2741a148342241bd2Af50",
  "0xFe568475CFc25563B5A5E9769f56b61dF85e7Dec",
  "0x767D222a509D107522e50161CA17FfCF0e5AA3dE",
  "0xEdE040A7E37C6eAa3a6E0Dbf4b60a4b729b84741",
  "0xeA99bF156F30bdC748d452D8A4e178fBE762762d",
  "0x9eeCEEC01aC7bE64C747B235552A2357af8534c1",
  "0xd783947ce4924147f35b319bd247ee628e7fb0be",
  "0x188502B2C3cF130B46cA18F9e35147c16685e9d2",
]

export const notAllowedCountries = [
  "BY",
  "CD",
  "CI",
  "CU",
  "IR",
  "IQ",
  "LB",
  "LR",
  "LY",
  "KP",
  "MM",
  "SD",
  "SS",
  "SY",
  "RU",
  "VE",
  "YE",
  "ZW",
]