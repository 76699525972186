import { useCallback } from "react";
import { useWalletClient, useWriteContract } from "wagmi"
import { useQueryClient } from '@tanstack/react-query'
import { BaseError, getAddress } from "viem";

import { useChainActions } from "../network";
import {  metamaskTxRejectedError } from "../../constants/network";
import { errorNotification, useTransactionToasts } from "../../components/common";
import { FuulManagerAbi } from "../../abi/FuulManager.abi";


export const useIncentivesTransactions = () => {
  const { data: walletClient } = useWalletClient();
  const { writeContractAsync } = useWriteContract();
  const { onSwitchChain } = useChainActions()
  const queryClient = useQueryClient()
  const toasts = useTransactionToasts()

  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['userRewards'].includes(queryKey.at(0) as string)
      }),
    [queryClient],
  )

  const onClaimCtx = async (amount: bigint) => {
    if (!walletClient) return
    if (!(await onSwitchChain())) return

    const claimChecks = [
      {
        projectAddress: getAddress('0x7C2ea7FbEB033431D9AA849FBAa6b678e3BAC463'), // On the Handy Links section on the app
        currency: getAddress('0xbb22ff867f8ca3d5f2251b4084f6ec86d4666e14'),
        amount: amount,
        tokenIds: [], // Empty because it's an ERC20
        amounts: [], // Empty because it's an ERC20
      },
    ];

    // Get FuulManager contract
    const fuulManagerAddress = "0xC38E3A10B5818601b29c83F195E8b5854AAE45aF";

    try {
      const hash = await writeContractAsync({
        address: fuulManagerAddress,
        abi: FuulManagerAbi,
        functionName: "claim",
        args: [claimChecks],
      })
      /* const publicClient = getPublicClient(chainId)
      const { request } = await publicClient.simulateContract({
        address: fuulManagerAddress,
        abi: FuulManagerAbi,
        functionName: "claim",
        args: [claimChecks],
        account: walletClient.account.address,
      })
 
      const hash = await walletClient.writeContract(request) */
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "CTX has been claimed",
          errorMsg: "Failed to claim CTX",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 5000)
          }
        }
      )
      return true;
    } catch (err) {
      console.log("claim error: ", err)
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to claim")
        }
      } else {
        errorNotification("Error: Failed to claim")
      }
      return false;
    }
  }

  return {
    onClaimCtx
  }
}