import React from "react"
import { Dropdown, Image, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next";

import { useIndexVaultContext } from "../../../contexts/indexVaultContext"
import { IndexVaultsMetadata, SupportedPockets } from "../../../constants/vaults"
import { CustomTooltip } from "../../common";
import { TokenMetadata } from "../../../constants/tokens"
import { capitalize } from "../../../utils/utils"
import { VaultTxList } from "../constants"


export const CollateralList = () => {
  const { chainId, selectedIndex, setSelectedVault, selectedVault } = useIndexVaultContext();
  const indexVaultsMetadata = IndexVaultsMetadata[chainId][selectedIndex];
  const tokenMetadata = TokenMetadata[selectedVault.collateral]

  return (
    <Stack direction="vertical" gap={1}>
      <h6 className="text-muted small">Collateral</h6>
      <Dropdown
        onSelect={(eventKey) => { 
          const vault = indexVaultsMetadata.find((vault) => vault.collateral === eventKey) 
          if (vault) {
            setSelectedVault(vault)
          }
        }}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          variant="success"
        >
          <Stack direction="horizontal" gap={2}>
            <Image src={tokenMetadata.icon} height={22} width={22} />
            <h6 className="title">
              {tokenMetadata.symbol.toUpperCase()}
            </h6>  
          </Stack>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {indexVaultsMetadata.map((vault) => {
            const tokenMetadata = TokenMetadata[vault.collateral]
            return (
              <Dropdown.Item key={`usdc`} eventKey="USDC">
                <Stack direction="horizontal" gap={2}>
                  <Image src={tokenMetadata.icon} height={22} width={22} />
                  <div className="market-desc">
                    <h6 className="title">
                      {tokenMetadata.symbol.toUpperCase()}
                    </h6>
                  </div>
                </Stack>  
              </Dropdown.Item>
              )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Stack>  
  );
}

export const PocketList = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { selectedPocket, setSelectedPocket, selectedVault } = useIndexVaultContext();

  return (
    <Stack
      direction="horizontal"
      gap={1}
      className="align-items-center justify-content-between mt-1 mb-0 p-1 border-box"
    >
      <Stack className="w-60 ps-1" direction="horizontal" gap={2}>
        <CustomTooltip
          id="tvault-type"
          msg={t("tooltip.vault-type")}
          showIcon={true}
          iconOnLeft={true}
          placement="top"
          iconSize={12}
        >
          <h6 className="ps-1">{t("vault-type")}</h6>
        </CustomTooltip>  
      </Stack>  
      <Stack className="w-40" direction="horizontal" gap={2}>
        <Dropdown onSelect={(eventKey) => setSelectedPocket(eventKey as SupportedPockets || SupportedPockets.aave)}>
          <Dropdown.Toggle
            id="dropdown-basic"
            variant="success"
            className="px-2 py-1"
            disabled={disabled}
            style={{ borderRadius: "3px", borderColor: "#303030" }}
          >
            <h6 style={{ fontSize: "0.95rem" }}>
              {capitalize(selectedPocket)}
            </h6>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(SupportedPockets).map((pocket) => {
              const p = selectedVault.pockets[pocket as SupportedPockets]
              if (!p) return <></>

              return (
                <Dropdown.Item key={pocket} eventKey={pocket}>
                  <h6 style={{ fontSize: "0.95rem" }}>
                    {capitalize(pocket)}
                  </h6>
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Stack>  
    </Stack>
  );
}

export const VaultTxLabel = (vaultTx: VaultTxList) => { 
  switch (vaultTx) {
    case VaultTxList.depositMint:
      return "deposit-and-mint"
    case VaultTxList.deposit:
      return "deposit"
    case VaultTxList.mint:
      return "mint"
    case VaultTxList.burnWithdraw:
      return "burn-and-withdraw"
    case VaultTxList.withdraw:
      return "withdraw"
    case VaultTxList.burn:
      return "burn"
  }
}
