
export enum TimeFrames {
  OneMinute = "1m",
  TwoMinutes = "2m",
  ThreeMinutes = "3m",
  FiveMinutes = "5m",
  TenMinutes = "10m",
  FifteenMinutes = "15m",
  TwentyMinutes = "20m",
  ThirtyMinutes = "30m",
  FortyFiveMinutes = "45m",
  OneHour = "1h",
  TwoHours = "2h",
  ThreeHours = "3h",
  FourHours = "4h",
  TwelveHours = "12h",
  OneDay = "1d",
  OneWeek = "1w",
  OneMonth = "1M",
}

export enum PricesApiTimeIntervals {
  OneSecond = "1s",
  OneMinute = "1m",
  OneHour = "1h",
  OneDay = "1d",
}


export type TimeFramesType = {
  [timeframe in TimeFrames]: {
    label: string,
    shortLabel: string,
    seconds: number,
    interval: PricesApiTimeIntervals,
    refetchInterval: number,
    disabled?: boolean,
  }
};

export const TimeFramesMetadata: TimeFramesType = {
  [TimeFrames.OneMinute]: {
    label: "1 minute",
    shortLabel: "1m",
    seconds: 60,
    interval: PricesApiTimeIntervals.OneSecond,
    refetchInterval: 2000,
  },
  [TimeFrames.TwoMinutes]: {
    label: "2 minutes",
    shortLabel: "2m",
    seconds: 120,
    interval: PricesApiTimeIntervals.OneSecond,
    refetchInterval: 2000,
  },
  [TimeFrames.ThreeMinutes]: {
    label: "3 minutes",
    shortLabel: "3m",
    seconds: 180,
    interval: PricesApiTimeIntervals.OneSecond,
    refetchInterval: 2000,
  },
  [TimeFrames.FiveMinutes]: {
    label: "5 minutes",
    shortLabel: "5m",
    seconds: 300,
    interval: PricesApiTimeIntervals.OneSecond,
    refetchInterval: 2000,
  },
  [TimeFrames.TenMinutes]: {
    label: "10 minutes",
    shortLabel: "10m",
    seconds: 600,
    interval: PricesApiTimeIntervals.OneSecond,
    refetchInterval: 3000,
  },
  [TimeFrames.FifteenMinutes]: {
    label: "15 minutes",
    shortLabel: "15m",
    seconds: 900,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 5000,
  },
  [TimeFrames.TwentyMinutes]: {
    label: "20 minutes",
    shortLabel: "20m",
    seconds: 1200,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 5000,
  },
  [TimeFrames.ThirtyMinutes]: {
    label: "30 minutes",
    shortLabel: "30m",
    seconds: 1800,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 5000,
  },
  [TimeFrames.FortyFiveMinutes]: {
    label: "45 minutes",
    shortLabel: "45m",
    seconds: 2700,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 5000,
  },
  [TimeFrames.OneHour]: {
    label: "1 hour",
    shortLabel: "1h",
    seconds: 3600,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 5000,
  },
  [TimeFrames.TwoHours]: {
    label: "2 hours",
    shortLabel: "2h",
    seconds: 7200,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 5000,
  },
  [TimeFrames.ThreeHours]: {
    label: "3 hours",
    shortLabel: "3h",
    seconds: 10800,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 7000,
  },
  [TimeFrames.FourHours]: {
    label: "4 hours",
    shortLabel: "4h",
    seconds: 14400,
    interval: PricesApiTimeIntervals.OneMinute,
    refetchInterval: 7000,
  },
  [TimeFrames.TwelveHours]: {
    label: "12 hours",
    shortLabel: "12h",
    seconds: 43200,
    interval: PricesApiTimeIntervals.OneHour,
    refetchInterval: 60000,
  },
  [TimeFrames.OneDay]: {
    label: "24 hours",
    shortLabel: "24h",
    seconds: 86400,
    interval: PricesApiTimeIntervals.OneHour,
    refetchInterval: 60000,
  },
  [TimeFrames.OneWeek]: {
    label: "1 week",
    shortLabel: "1w",
    seconds: 7 * 86400,
    disabled: false,
    interval: PricesApiTimeIntervals.OneHour,
    refetchInterval: 60000,
  },
  [TimeFrames.OneMonth]: {
    label: "1 Month",
    shortLabel: "1M",
    seconds: 30 * 86400,
    disabled: true,
    interval: PricesApiTimeIntervals.OneDay,
    refetchInterval: 60000,
  },
};

export const chartColors = {
  backgroundColor: "#171B26",
  lineColor: '#A440F2',
  textColor: "#e1e0ec",
  areaTopColor: "#a540f282",
  areaBottomColor: "#a540f246",
  gridColor: "#36383A",
  crosshairColor: "#C3BCDB44",
  crosshairLabelBackgroundColor: "#494866",
}