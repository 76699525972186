export const FuulManagerAbi = [
    {
        "inputs": [{ "internalType": "address", "name": "attributor", "type": "address" },
            { "internalType": "address", "name": "pauser", "type": "address" },
            { "internalType": "address", "name": "unpauser", "type": "address" }, { "internalType": "address", "name": "acceptedERC20CurrencyToken", "type": "address" }, { "internalType": "uint256", "name": "initialTokenLimit", "type": "uint256" }, { "internalType": "uint256", "name": "initialNativeTokenLimit", "type": "uint256" }], "stateMutability": "nonpayable", "type": "constructor"
    },
    { "inputs": [], "name": "InvalidArgument", "type": "error" },
    { "inputs": [], "name": "LimitAlreadySet", "type": "error" },
    { "inputs": [], "name": "OverTheLimit", "type": "error" },
    { "inputs": [], "name": "Unauthorized", "type": "error" },
    { "inputs": [], "name": "ZeroAddress", "type": "error" },
    {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }],
        "name": "ClaimCooldownUpdated", "type": "event"
    },
    {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "account", "type": "address" }], "name": "Paused", "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
            { "indexed": true, "internalType": "bytes32", "name": "previousAdminRole", "type": "bytes32" },
            { "indexed": true, "internalType": "bytes32", "name": "newAdminRole", "type": "bytes32" }], "name": "RoleAdminChanged", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "indexed": true, "internalType": "address", "name": "account", "type": "address" }, { "indexed": true, "internalType": "address", "name": "sender", "type": "address" }], "name": "RoleGranted", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
        { "indexed": true, "internalType": "address", "name": "account", "type": "address" }, { "indexed": true, "internalType": "address", "name": "sender", "type": "address" }], "name": "RoleRevoked", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "token", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }], "name": "TokenLimitAdded", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "token", "type": "address" },
        { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }], "name": "TokenLimitUpdated", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "account", "type": "address" }],
        "name": "Unpaused", "type": "event"
    }, {
        "inputs": [], "name": "ATTRIBUTOR_ROLE", "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [], "name": "DEFAULT_ADMIN_ROLE", "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [], "name": "PAUSER_ROLE", "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [], "name": "UNPAUSER_ROLE", "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "stateMutability": "view", "type": "function"
    }, { "inputs": [{ "internalType": "address", "name": "tokenAddress", "type": "address" }, { "internalType": "uint256", "name": "claimLimitPerCooldown", "type": "uint256" }], "name": "addCurrencyLimit", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, {
        "inputs": [{
            "components": [{ "internalType": "address", "name": "projectAddress", "type": "address" }, {
                "components": [{ "internalType": "address", "name": "currency", "type": "address" },
                { "internalType": "address", "name": "partner", "type": "address" }, { "internalType": "address", "name": "endUser", "type": "address" }, { "internalType": "uint256", "name": "amountToPartner", "type": "uint256" }, { "internalType": "uint256", "name": "amountToEndUser", "type": "uint256" }, { "internalType": "bytes32", "name": "proof", "type": "bytes32" }, { "internalType": "bytes32", "name": "proofWithoutProject", "type": "bytes32" }], "internalType": "struct IFuulProject.Attribution[]", "name": "projectAttributions", "type": "tuple[]"
            }], "internalType": "struct IFuulManager.AttributionEntity[]", "name": "attributions", "type": "tuple[]"
        }, { "internalType": "address", "name": "attributorFeeCollector", "type": "address" }], "name": "attributeConversions", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{
            "components": [
                { "internalType": "address", "name": "projectAddress", "type": "address" },
                { "internalType": "address", "name": "currency", "type": "address" },
                { "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "internalType": "uint256[]", "name": "tokenIds", "type": "uint256[]" },
                { "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }
            ],
            "internalType": "struct IFuulManager.ClaimCheck[]", "name": "claimChecks", "type": "tuple[]"
        }],
        "name": "claim",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [], "name": "claimCooldown", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
        "name": "currencyLimits", "outputs": [{ "internalType": "uint256", "name": "claimLimitPerCooldown", "type": "uint256" }, { "internalType": "uint256", "name": "cumulativeClaimPerCooldown", "type": "uint256" }, { "internalType": "uint256", "name": "claimCooldownPeriodStarted", "type": "uint256" }], "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }],
        "name": "getRoleAdmin", "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }], "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "uint256", "name": "index", "type": "uint256" }],
        "name": "getRoleMember", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }], "name": "getRoleMemberCount",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "address", "name": "account", "type": "address" }],
        "name": "grantRole", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "address", "name": "account", "type": "address" }],
        "name": "hasRole", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function"
    }, { "inputs": [], "name": "isPaused", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
    { "inputs": [], "name": "pauseAll", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
    { "inputs": [], "name": "paused", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
    {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "address", "name": "account", "type": "address" }],
        "name": "renounceRole", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "address", "name": "account", "type": "address" }],
        "name": "revokeRole", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, { "inputs": [{ "internalType": "uint256", "name": "period", "type": "uint256" }], "name": "setClaimCooldown", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
    {
        "inputs": [{ "internalType": "address", "name": "tokenAddress", "type": "address" },
        { "internalType": "uint256", "name": "limit", "type": "uint256" }], "name": "setCurrencyTokenLimit", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" }], "name": "supportsInterface",
        "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function"
    }, { "inputs": [], "name": "unpauseAll", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
    {
        "inputs": [{ "internalType": "address", "name": "", "type": "address" }, { "internalType": "address", "name": "", "type": "address" }],
        "name": "usersClaims", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function"
    }
] as const