import BigNumber from 'bignumber.js';
import { BigNumberValue, valueToZDBigNumber } from './bignumber';
import { RAY, rayPow } from './aaveRayMath';


export const SECONDS_PER_YEAR = 31536000;

export function calculateCompoundedRate({
  rate,
  duration,
}: { rate: BigNumberValue, duration: BigNumberValue }): BigNumber {
    
  return rayPow(
    valueToZDBigNumber(rate).dividedBy(SECONDS_PER_YEAR).plus(RAY),
    duration,
  ).minus(RAY);
}
