import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { arbitrum } from "viem/chains";
import { UsdcAddresses } from "../../../constants/contracts";
import { LifiForm } from ".";


export const LifiUsdcButton = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowModal(!showModal)}
        style={{
          borderRadius: "10px",
          padding: "1.5px 3px",
          width: "4.5rem",
          fontSize: "0.9rem"
        }}
      >
        USDC
      </Button>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} className="lifi-modal">
          <Modal.Header closeButton />
          <Modal.Body>
            <LifiForm toToken={UsdcAddresses[arbitrum.id]} toChain={arbitrum.id} />
          </Modal.Body>
        </Modal>
      )}
    </>  
  );
};
