import { formatUnits } from "viem";
import { base, baseSepolia } from "viem/chains";

import { MinSafeHealthFactorPct, VaultStatus, VaultStatusColor } from "./constants";
import { ChainTokens, SupportedTokens } from "../../constants/tokens";
import { SupportedChainIdType } from "../../constants/network";


export const getStatusFromRatio = (treshold: number, ratio: number) => { 
  if (ratio <= treshold) {
    return VaultStatus.error;
  } else if (ratio < treshold * MinSafeHealthFactorPct) {
    return VaultStatus.danger;
  } else {
    return VaultStatus.safe;
  }
}

export const getStatusFromRatioBI = (tresholdBI: bigint, ratioBI: bigint) => { 
  const treshold = parseFloat(formatUnits(tresholdBI, 18));
  const ratio = parseFloat(formatUnits(ratioBI, 18));  

  return getStatusFromRatio(treshold, ratio);
}

export const getStatusColor = (status: VaultStatus) => { 
  return VaultStatusColor[status];
}

export const calcHealthFactor = (
  collateral: bigint,
  debt: bigint,
  collateralPrice: bigint,
  indexPrice: bigint,
  collateralDecimals: number,
  indexDecimals: number,
) => { 
  const collateralValue = formatUnits(collateral * collateralPrice, collateralDecimals);
  const mintedValue = formatUnits(debt * indexPrice, indexDecimals);
    
  if (parseFloat(mintedValue) === 0) { 
    return 0;
  }
  return parseFloat(collateralValue) / parseFloat(mintedValue);
}

export const calcCollateralFromDebtAndHealthFactor = (
  debt: bigint,
  healthFactor: number,
  collateralPrice: bigint,
  indexPrice: bigint,
  collateralDecimals: number,
  indexDecimals: number,
) => {
  const indexValue = formatUnits(debt * indexPrice, indexDecimals);
  const cPrice = parseFloat(formatUnits(collateralPrice, collateralDecimals));

  if (cPrice === 0) { 
    return 0;
  }
  return parseFloat(indexValue) * healthFactor / cPrice;
}

export const calcDebtFromCollateralAndHealthFactor = (
  collateral: bigint,
  healthFactor: number,
  collateralPrice: bigint,
  indexPrice: bigint,
  collateralDecimals: number,
  indexDecimals: number,
) => { 
  const collateralValue = parseFloat(formatUnits(collateral, collateralDecimals)) * parseFloat(formatUnits(collateralPrice, 18));
  const divisor = parseFloat(formatUnits(indexPrice, indexDecimals)) * (healthFactor + 0.00005);

  if (divisor === 0) {
    return 0;
  }
  return collateralValue / divisor;
}

export const aavePoolUrl = (chainId: SupportedChainIdType, token: SupportedTokens) => {
  const address = ChainTokens[chainId][token].toLowerCase();

  if (chainId === base.id) {
    return `https://app.aave.com/reserve-overview/?underlyingAsset=${address}&marketName=proto_base_v3`;
  } else if (chainId === baseSepolia.id) {
    return `https://app.aave.com/reserve-overview/?underlyingAsset=${address}&marketName=proto_base_sepolia_v3`;
  } else {
    return `https://app.aave.com/reserve-overview/?underlyingAsset=${address}&marketName=proto_sepolia_v3`;
  }
}
