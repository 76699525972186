import React, { useEffect, useRef, useState } from 'react';
import { createChart, ColorType, CrosshairMode, LastPriceAnimationMode, BarPrice } from 'lightweight-charts';

import { useIndexChartPrices, useIndexPriceLastUpdate } from '../../hooks/indexes/graph';
import { SupportedIndexes } from '../../constants/indexes';
import { chartColors } from './constants';


export const ChartFromGraph = ({
  indexSymbol,
  logicalFromTimestamp,
  fromTimestamp,
}: {
  indexSymbol: string,
  logicalFromTimestamp: number,
  fromTimestamp: number,
}) => {
  const chartContainerRef = useRef<any>();
  const chart = useRef<any>();
  const series = useRef<any>();
  const [prices, setPrices] = useState<any[]>([]);
  const [lastPriceOnChart, setLastPriceOnChart] = useState({ time: 0, value: 0 });
  const { data: pricesData, isLoading } = useIndexChartPrices(indexSymbol as SupportedIndexes, fromTimestamp);
  const { data: lastPrice } = useIndexPriceLastUpdate( indexSymbol as SupportedIndexes, 60000);

  useEffect(() => {
    if (!isLoading && pricesData) {
      setPrices(pricesData?.prices);
    }
  },
    // eslint-disable-next-line
    [isLoading, pricesData]
  )

  useEffect(
    () => {
      if (prices.length === 0) return;

      const handleResize = () => {
        if (chartContainerRef.current !== null) {
          chart.current.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
          });
        }
      };

      chart.current = createChart(chartContainerRef.current, {
        crosshair: {
          mode: CrosshairMode.Normal,
          vertLine: {
            color: chartColors.crosshairColor,
            labelBackgroundColor: chartColors.crosshairLabelBackgroundColor,
          },
          horzLine: {
            color: chartColors.crosshairColor,
            labelBackgroundColor: chartColors.crosshairLabelBackgroundColor,
          },
        },
        layout: {
          background: { type: ColorType.Solid, color: chartColors.backgroundColor },
          textColor: chartColors.textColor,
        },
        grid: {
          vertLines: {
            color: chartColors.gridColor,
          },
          horzLines: {
            color: chartColors.gridColor,
          },
        },
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
        localization: {
          priceFormatter: (price: BarPrice) => {
            return price.toFixed(4);
          },
        },
        rightPriceScale: {
          ticksVisible: true,
        },        
      });

      chart.current.timeScale().scrollToPosition(10);
      series.current = chart.current.addAreaSeries({
        lineColor: chartColors.lineColor,
        topColor: chartColors.areaTopColor,
        bottomColor: chartColors.areaBottomColor,
        lastPriceAnimation: LastPriceAnimationMode.Continuous,
      });
      series.current.applyOptions({
        priceFormat: {
          type: 'custom',
          minMove: 0.0001,
          formatter: (price: BarPrice) => price.toFixed(4),
        },
      });
      series.current.setData(prices);      
      
      if (prices.length > 0) { 
        const fromLogicalIndex = prices.findIndex((price: any) => price.time >= logicalFromTimestamp);
        chart.current.timeScale().setVisibleLogicalRange({
          from: fromLogicalIndex,
          to: prices.length,
        });
      } else {
        // chart.current.timeScale().fitContent();
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.current.remove();
      };
    },
    // eslint-disable-next-line
    [prices]
  );

  useEffect(() => {
    if (lastPrice && lastPrice.time > 0 && series.current && lastPrice.time >= lastPriceOnChart.time) {
      /* let updateLastTimeOnChart = timeFrameMetadata.interval === PricesApiTimeIntervals.OneHour;
      if (updateLastTimeOnChart) {
        updateLastTimeOnChart = lastPrice.time - lastPriceOnChart.time < 900
      } */
      series.current.update({
        time: lastPrice.time,
        value: lastPrice.value
      });
      setLastPriceOnChart(lastPrice);
    }
  },
    // eslint-disable-next-line
    [lastPrice]
  );

  return (
    <div style={{ height: "100%" }} ref={chartContainerRef} />
  );
};
