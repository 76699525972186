import React, { useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { Toolbar } from './Toolbar';
import { Chart } from './Chart';
import { TimeFrames, TimeFramesMetadata } from './constants';
import { SupportedIndexes } from '../../constants/indexes';
import { useCryptexProductsContext } from '../../contexts';
import { ChartFromGraph } from './ChartFromGraph';


export const TradingViewChart = ({ defaultTimeFrame = TimeFrames.OneMinute } : { defaultTimeFrame?: TimeFrames }) => {
  const { isIndexes } = useCryptexProductsContext();
  const [currentTimeFrame, setCurrentTimeFrame] = useState(defaultTimeFrame);

  const { logicalFromTimestamp, fromTimestamp } = useMemo(() => {
    const now = new Date();
    const logicalFromTimestamp = (now.getTime() / 1000) - TimeFramesMetadata[currentTimeFrame].seconds;
    
    const tfMetadata = TimeFramesMetadata[currentTimeFrame].seconds <= TimeFramesMetadata[TimeFrames.OneHour].seconds
      ? TimeFramesMetadata[TimeFrames.OneHour]
      : TimeFramesMetadata[currentTimeFrame];
    const fromTimestamp = (now.getTime() / 1000) - tfMetadata.seconds;

    return {
      logicalFromTimestamp: Math.floor(logicalFromTimestamp),
      fromTimestamp: Math.floor(fromTimestamp),
    };
  }, [currentTimeFrame])

  return (
    <Stack direction="vertical" className="chart-wrapper w-100" style={{ marginTop: "0rem" }} >
      <>
        <Toolbar
          supportedIndex={!isIndexes() ? SupportedIndexes.meem : SupportedIndexes.tcap}
          currentTimeFrame={currentTimeFrame}
          handleTimeFrameChange={(timeFrame) => setCurrentTimeFrame(timeFrame)}
        />
        {!isIndexes() ? (
          <Chart
            indexSymbol={SupportedIndexes.meem}
            logicalFromTimestamp={logicalFromTimestamp}
            fromTimestamp={fromTimestamp}
            timeFrame={ currentTimeFrame}
          />
        ) : (
          <ChartFromGraph
            indexSymbol={SupportedIndexes.tcap}
            logicalFromTimestamp={logicalFromTimestamp}
            fromTimestamp={fromTimestamp}
          />
        )}
      </>
    </Stack>
  );
};

