import React, { useState } from "react";
import { Alert, Button, Image, Stack } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";

import ctxIcon from "../../../assets/ctx-icon.png";


export const IncentivesBanner = () => { 
  const location = useLocation();
  const [show, setShow] = useState(true);
  
  if (!show || location.pathname.includes("emissions")) return <></>;
  
  return (
    <Alert onClose={() => setShow(false)} className="notification-box">
      <Stack direction="horizontal">
        <Stack direction="horizontal" className="w-90" gap={2}>
          <Image className="token-icon" src={ctxIcon} width={26} height={26} />
          <p>
            <span className="text-muted-light bold">178,000 in CTX</span> emissions are available to TCAP LPs via our DAO approved{" "}
            <a href="/emissions" target="_blank" rel="noreferrer" style={{ color: "#f5f5f5" }}>
              Emissions Program.
            </a>
          </p>
        </Stack>
        <Stack direction="horizontal" className="w-10 justify-content-end">
          <Button variant="outline-primary" className="p-0" onClick={() => setShow(false)}>
            <AiOutlineClose fill="white" size={20} />
          </Button>
        </Stack>  
      </Stack>  
    </Alert>  
  )
}
