export const VaultAbi = [
    {
        "inputs": [{ "internalType": "contract ITCAPV2", "name": "tCAPV2_", "type": "address" },
        { "internalType": "contract IERC20", "name": "collateral_", "type": "address" },
            { "internalType": "contract IPermit2", "name": "permit2_", "type": "address" }],
        "stateMutability": "nonpayable", "type": "constructor"
    }, { "inputs": [], "name": "AccessControlBadConfirmation", "type": "error" },
    {
        "inputs": [
            { "internalType": "address", "name": "account", "type": "address" },
            { "internalType": "bytes32", "name": "neededRole", "type": "bytes32" }
        ],
        "name": "AccessControlUnauthorizedAccount", "type": "error"
    },
    { "inputs": [], "name": "InsufficientMintedAmount", "type": "error" },
    { "inputs": [], "name": "InvalidInitialization", "type": "error" },
    { "inputs": [], "name": "InvalidOracle", "type": "error" },
    { "inputs": [], "name": "InvalidToken", "type": "error" },
    {
        "inputs": [{ "internalType": "enum IVault.ErrorCode", "name": "code", "type": "uint8" }],
        "name": "InvalidValue", "type": "error"
    }, { "inputs": [], "name": "LoanHealthy", "type": "error" },
    { "inputs": [], "name": "LoanNotHealthy", "type": "error" },
    { "inputs": [], "name": "NotInitializing", "type": "error" },
    {
        "inputs": [{ "internalType": "uint96", "name": "pocketId", "type": "uint96" }],
        "name": "PocketNotEnabled", "type": "error"
    }, {
        "inputs": [{ "internalType": "uint256", "name": "value", "type": "uint256" }],
        "name": "SafeCastOverflowedUintToInt", "type": "error"
    },
    {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "Burned", "type": "event"
    },
    {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "indexed": false, "internalType": "uint256", "name": "collateralAmount", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "shares", "type": "uint256" }],
            "name": "Deposited", "type": "event"
    },
    {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "indexed": true, "internalType": "address", "name": "feeRecipient", "type": "address" },
            { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }],
            "name": "FeeCollected", "type": "event"
    },
    {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "newFeeRecipient", "type": "address" }],
        "name": "FeeRecipientUpdated", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint64", "name": "version", "type": "uint64" }],
        "name": "Initialized", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint16", "name": "fee", "type": "uint16" }],
        "name": "InterestRateUpdated", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "liquidator", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "indexed": false, "internalType": "uint256", "name": "collateralAmount", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "mintAmount", "type": "uint256" }], "name": "Liquidated", "type": "event"
    }, {
        "anonymous": false, "inputs": [{
            "components": [{ "internalType": "uint64", "name": "threshold", "type": "uint64" },
                { "internalType": "uint64", "name": "penalty", "type": "uint64" },
                { "internalType": "uint64", "name": "minHealthFactor", "type": "uint64" },
                { "internalType": "uint64", "name": "maxHealthFactor", "type": "uint64" }],
            "indexed": false, "internalType": "struct IVault.LiquidationParams", "name": "newLiquidationParams", "type": "tuple"
        }], "name": "LiquidationParamsUpdated", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "Minted", "type": "event"
    },
    {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "newOracle", "type": "address" }],
        "name": "OracleUpdated", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
        { "indexed": false, "internalType": "contract IPocket", "name": "pocket", "type": "address" }], "name": "PocketAdded", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint96", "name": "pocketId", "type": "uint96" }],
        "name": "PocketDisabled", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
            { "indexed": true, "internalType": "bytes32", "name": "previousAdminRole", "type": "bytes32" },
            { "indexed": true, "internalType": "bytes32", "name": "newAdminRole", "type": "bytes32" }], "name": "RoleAdminChanged", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
            { "indexed": true, "internalType": "address", "name": "account", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "sender", "type": "address" }], "name": "RoleGranted", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "role", "type": "bytes32" },
            { "indexed": true, "internalType": "address", "name": "account", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "sender", "type": "address" }], "name": "RoleRevoked", "type": "event"
    }, {
        "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "indexed": true, "internalType": "address", "name": "recipient", "type": "address" },
            { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "shares", "type": "uint256" }], "name": "Withdrawn", "type": "event"
    }, {
        "inputs": [], "name": "COLLATERAL", "outputs": [{ "internalType": "contract IERC20", "name": "", "type": "address" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [], "name": "DEFAULT_ADMIN_ROLE", "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [], "name": "TCAPV2",
        "outputs": [{ "internalType": "contract ITCAPV2", "name": "", "type": "address" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "contract IPocket", "name": "pocket", "type": "address" }],
        "name": "addPocket",
        "outputs": [{ "internalType": "uint96", "name": "pocketId", "type": "uint96" }],
        "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{ "internalType": "uint96", "name": "pocketId", "type": "uint96" },
        { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "burn", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{ "internalType": "address", "name": "user", "type": "address" },
        { "internalType": "uint96", "name": "pocketId", "type": "uint96" }],
        "name": "collateralOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "collateralValueOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{ "internalType": "address", "name": "user", "type": "address" },
        { "internalType": "uint96", "name": "pocketId", "type": "uint96" }],
        "name": "collateralValueOfUser",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        inputs: [
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" }
        ],
        name: "deposit",
        outputs: [{ "internalType": "uint256", "name": "shares", "type": "uint256" }],
        stateMutability: "nonpayable",
        type: "function"
    }, {
        "inputs": [
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" },
            {
                "components": [
                    {
                        "components": [
                            { "internalType": "address", "name": "token", "type": "address" },
                            { "internalType": "uint256", "name": "amount", "type": "uint256" }
                        ],
                        "internalType": "struct ISignatureTransfer.TokenPermissions",
                        "name": "permitted",
                        "type": "tuple"
                    },
                    { "internalType": "uint256", "name": "nonce", "type": "uint256" },
                    { "internalType": "uint256", "name": "deadline", "type": "uint256" }
                ],
                "internalType": "struct ISignatureTransfer.PermitTransferFrom",
                "name": "permit",
                "type": "tuple"
            },
            { "internalType": "bytes", "name": "signature", "type": "bytes" }],
        "name": "depositWithPermit",
        "outputs": [{ "internalType": "uint256", "name": "shares", "type": "uint256" }],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint96", "name": "pocketId", "type": "uint96" }],
        "name": "disablePocket", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [], "name": "feeRecipient", "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }], "name": "getRoleAdmin",
        "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }], "stateMutability": "view", "type": "function"
    }, { "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "address", "name": "account", "type": "address" }], "name": "grantRole", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, {
        "inputs": [{ "internalType": "bytes32", "name": "role", "type": "bytes32" }, { "internalType": "address", "name": "account", "type": "address" }],
        "name": "hasRole", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function"
    }, { "inputs": [{ "internalType": "address", "name": "user", "type": "address" }, { "internalType": "uint96", "name": "pocketId", "type": "uint96" }], "name": "healthFactor", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }, {
        "inputs": [{ "internalType": "address", "name": "admin", "type": "address" },
            { "internalType": "uint16", "name": "initialFee", "type": "uint16" },
            { "internalType": "address", "name": "oracle_", "type": "address" },
            { "internalType": "address", "name": "feeRecipient_", "type": "address" },
            {
                "components": [{ "internalType": "uint64", "name": "threshold", "type": "uint64" },
                    { "internalType": "uint64", "name": "penalty", "type": "uint64" },
                    { "internalType": "uint64", "name": "minHealthFactor", "type": "uint64" },
                    { "internalType": "uint64", "name": "maxHealthFactor", "type": "uint64" }],
                "internalType": "struct IVault.LiquidationParams",
                "name": "liquidationParams_",
                "type": "tuple"
            }],
        "name": "initialize", "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [], "name": "interestRate",
        "outputs": [{ "internalType": "uint16", "name": "", "type": "uint16" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [], "name": "latestPrice",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "internalType": "uint256", "name": "burnAmount", "type": "uint256" }
        ],
        "name": "liquidate",
        "outputs": [{ "internalType": "uint256", "name": "liquidationReward", "type": "uint256" }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "liquidationParams",
        "outputs": [{
            "components": [
                { "internalType": "uint64", "name": "threshold", "type": "uint64" },
                { "internalType": "uint64", "name": "penalty", "type": "uint64" },
                { "internalType": "uint64", "name": "minHealthFactor", "type": "uint64" },
                { "internalType": "uint64", "name": "maxHealthFactor", "type": "uint64" }
            ],
            "internalType": "struct IVault.LiquidationParams",
            "name": "params",
            "type": "tuple"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        inputs: [
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" }
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" }
        ],
        "name": "mintedAmountOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "mintedValueOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" }
        ],
        "name": "mintedValueOfUser",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        inputs: [
            { "internalType": "bytes[]", "name": "data", "type": "bytes[]" }
        ],
        name: "multicall",
        outputs: [{ "internalType": "bytes[]", "name": "results", "type": "bytes[]" }],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        "inputs": [], "name": "oracle",
        "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" }
        ],
        "name": "outstandingInterestOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "uint96", "name": "id", "type": "uint96" }],
        "name": "pocketEnabled",
        "outputs": [{
            "internalType": "bool",
            "name": "", "type": "bool"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "uint96", "name": "id", "type": "uint96" }],
        "name": "pockets",
        "outputs": [{ "internalType": "contract IPocket", "name": "", "type": "address" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [
            { "internalType": "bytes32", "name": "role", "type": "bytes32" },
            { "internalType": "address", "name": "callerConfirmation", "type": "address" }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            { "internalType": "bytes32", "name": "role", "type": "bytes32" },
            { "internalType": "address", "name": "account", "type": "address" }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" }],
        "name": "supportsInterface",
        "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" }
        ],
        "name": "takeFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "newFeeRecipient", "type": "address" }],
        "name": "updateFeeRecipient", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{ "internalType": "uint16", "name": "fee", "type": "uint16" }],
        "name": "updateInterestRate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{
            "components": [
                { "internalType": "uint64", "name": "threshold", "type": "uint64" },
                { "internalType": "uint64", "name": "penalty", "type": "uint64" },
                { "internalType": "uint64", "name": "minHealthFactor", "type": "uint64" },
                { "internalType": "uint64", "name": "maxHealthFactor", "type": "uint64" }
            ],
            "internalType": "struct IVault.LiquidationParams",
            "name": "newParams",
            "type": "tuple"
        }],
        "name": "updateLiquidationParams",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "address", "name": "newOracle", "type": "address" }],
        "name": "updateOracle", "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "version",
        "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            { "internalType": "uint96", "name": "pocketId", "type": "uint96" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" },
            { "internalType": "address", "name": "to", "type": "address" }
        ],
        "name": "withdraw",
        "outputs": [{ "internalType": "uint256", "name": "shares", "type": "uint256" }],
        "stateMutability": "nonpayable",
        "type": "function"
    }
] as const