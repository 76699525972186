import { SupportedTokens } from "../../constants/tokens";

export const MinSafeHealthFactorPct = 1.25;

export const NewVaultMaxAmount = 0.01;

export enum VaultTxList {
  depositMint = "depositMint",
  deposit = "deposit",
  mint = "mint",
  burnWithdraw = "burnWithdraw",
  withdraw = "withdraw",
  burn = "burn"
}

export enum FormNames {
  collateral = "collateral",
  indexAmount = "indexAmount",
  healthFactor = "healthFactor",
}

export enum VaultStatus {
  error = "error",
  danger = "unsafe",
  safe = "safe",
}

export const VaultStatusColor = {
  [VaultStatus.error]: "#ff0c0c",
  [VaultStatus.danger]: "#eb7413",
  [VaultStatus.safe]: "#03c620",
}

export const WithdrawAdjusment = {
  [SupportedTokens.usdc]: "0.0001",
  [SupportedTokens.weth]: "0.000001",
  [SupportedTokens.cbbtc]: "0.00000005",
  [SupportedTokens.lbtc]: "0.00000005",
}