import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { IGeolocationContext } from "../states";
import { notAllowedCountries } from "../../constants/network";


export const useGeolocationContext = (): IGeolocationContext => {
  const location = useLocation();
  const [countryCode, setCountryCode] = useState("US");

  const setCurrentCountryCode = React.useCallback((currentCode: string): void => {
    setCountryCode(currentCode);
  }, []);  
    
  const isRestricted = (): boolean => {
    if (location.pathname.includes("perpetuals")) {
      return countryCode === "US" || notAllowedCountries.includes(countryCode.toUpperCase());
    } else {
      return notAllowedCountries.includes(countryCode.toUpperCase());
    }
  };

  return {
    countryCode,
    setCurrentCountryCode,
    isRestricted,
  };
};
