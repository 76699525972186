import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Big6Math,
  Big18Math,
  BigOrZero,
  KeeperOracleAbi,
  PriceUpdate,
  SupportedMarket,
  last24hrBounds,
  pythPriceToBig18,
  unique,
  SupportedAsset,
} from '@perennial/sdk'
import { getAddress, zeroHash } from "viem";
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { usePerpetualsChainId, usePyth, usePythSubscription, useViemWsClient } from '../network'
import { useMarketOracles2 } from './chain'

import { PythDataFeedUrl, PythPriceFeedUrl } from '../../constants/network'
import { MarketHours, MarketMetadata, RewardAsset, RewardAssetData } from '../../constants/markets'
import { usePerennialSDKContext } from '../../contexts/perennialSdkContext';
import { SupportedIndexes } from '../../constants/indexes';
import { useIndexLivePrice } from '../indexes';


export const useMarket24HrHighLow = (asset: SupportedMarket) => {
  const metadata = MarketMetadata[asset]

  return useQuery({
    queryKey: ['market24HrHighLow', asset],
    enabled: !!metadata,
    queryFn: async () => {
      if (!metadata) return

      const { tvTicker, transform } = metadata
      const { from, to } = last24hrBounds()
      const request = await fetch(`${PythDataFeedUrl}/history?symbol=${tvTicker}&resolution=D&from=${from}&to=${to}`)
      const prices = (await request.json()) as { h: number[]; l: number[]; o: number[] }
      const highs = prices.h.map((h) => transform(Big18Math.fromFloatString(h.toFixed(18))))
      const lows = prices.l.map((l) => transform(Big18Math.fromFloatString(l.toFixed(18))))

      return {
        open: transform(Big18Math.fromFloatString(prices.o[0].toFixed(18))),
        high: Big6Math.max(...(metadata.isInverted ? lows : highs)),
        low: Big6Math.min(...(metadata.isInverted ? highs : lows)),
      }
    },
  })
}

export const useRewardAssetPrice = (rewardAsset: RewardAsset) => {
  const pyth = usePyth()

  return useQuery({
    queryKey: ['RewardAssetPrice'],
    queryFn: async () => {
      const { pythFeedId } = RewardAssetData[rewardAsset]
      const price = await pyth.getLatestPriceUpdates([pythFeedId])

      const uncheckedPrice = price?.parsed?.at(0)?.price
      return Big18Math.toDecimals(
        pythPriceToBig18(BigOrZero(uncheckedPrice?.price), uncheckedPrice?.expo ?? 0),
        Big6Math.FIXED_DECIMALS,
      )
    },
  })
}

export const useChainLivePrices2 = () => {
  const sdk = usePerennialSDKContext()
  const [prices, setPrices] = useState<{ [key in SupportedMarket]?: { price: bigint; untransformed: bigint } }>({})
  const indexesPrices = useIndexLivePrice()

  const [feedIds, feedToAsset] = useMemo(() => {
    const feedToAsset = sdk.supportedMarkets?.reduce((acc, market) => {
      const feed = MarketMetadata[market].pythFeedId
      if (!feed || feed === zeroHash || feed === MarketMetadata[SupportedAsset.meem].pythFeedId) return acc

      if (acc[feed]) {
        acc[feed].push(market)
      } else {
        acc[feed] = [market]
      }
      return acc
    }, {} as { [key: string]: SupportedMarket[] })

    const feedIds = Object.keys(feedToAsset ?? {})

    return [feedIds, feedToAsset]
  }, [sdk.supportedMarkets])

  const feedSubscription = usePythSubscription(feedIds)
  const onUpdate = useCallback(
    (priceFeed: PriceUpdate) => {
      const parsedData = priceFeed.parsed
      if (!parsedData) return
      parsedData.forEach((data) => {
        const price = data.price
        const normalizedPrice = pythPriceToBig18(BigOrZero(price?.price), price?.expo ?? 0)
        setPrices((prices) => ({
          ...prices,
          ...feedToAsset['0x' + data.id].reduce((acc, asset) => {
            const { transform } = MarketMetadata[asset]
            // Pyth price is has `expo` (negative number) decimals, normalize to expected 18 decimals by multiplying by 10^(18 + expo)
            acc[asset] = price
              ? {
                  price: transform(normalizedPrice),
                  untransformed: normalizedPrice,
                }
              : undefined
            return acc
          }, {} as { [key in SupportedMarket]?: { price: bigint; untransformed: bigint } }),
        }))
      })
    },
    [feedToAsset],
  )

  useEffect(() => {
    feedSubscription.on('updates', onUpdate)

    return () => {
      feedSubscription.off('updates', onUpdate)
    }
  }, [feedSubscription, onUpdate])

  useEffect(() => {
    if (indexesPrices) {
      setPrices((prices) => ({
        ...prices,
        ...Object.entries(indexesPrices).reduce((acc, [index, data]) => {
          const { price, untransformed } = data
          acc[index as SupportedIndexes] = { price, untransformed }
          return acc
        }, {} as { [key in SupportedIndexes]?: { price: bigint; untransformed: bigint } }),
      }))
    }
  }, [indexesPrices])

  return prices
}

export type LivePrices = Awaited<ReturnType<typeof useChainLivePrices2>>

const RefreshKeys = ['marketSnapshots2']
export const useRefreshKeysOnPriceUpdates2 = (invalidKeys: string[] = RefreshKeys) => {
  const chainId = usePerpetualsChainId()
  const queryClient = useQueryClient()
  const { data: marketOracles,  isPlaceholderData } = useMarketOracles2()
  const wsClient = useViemWsClient()

  const refresh = useCallback(async () => {
    // Wait a random amount of time between 0 and 5s to prevent rate limiting
    await new Promise((resolve) => setTimeout(resolve, 5000))
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) => invalidKeys.includes(queryKey.at(0) as string) && queryKey.includes(chainId),
    })
  }, [invalidKeys, queryClient, chainId])

  const oracleProviders = useMemo(() => {
    if (!marketOracles || isPlaceholderData) return []
    return unique(Object.values(marketOracles).flatMap((p) => p.subOracleAddress))
  }, [marketOracles, isPlaceholderData])

  useEffect(() => {
    if (!oracleProviders.length || !marketOracles) return
    const unwatchFns = oracleProviders.map((a) => {
      return [
        wsClient.watchContractEvent({
          address: a,
          abi: KeeperOracleAbi,
          eventName: 'OracleProviderVersionFulfilled',
          onLogs: (logs) => {
            const logAddresses = unique(logs.map((log) => getAddress(log.address)))
            Object.values(marketOracles)
              ?.filter((o) => logAddresses.includes(o.subOracleAddress))
              .forEach(() => refresh())
          },
        }),
      ]
    })
    return () => unwatchFns.flat().forEach((unwatch) => unwatch())
  }, [oracleProviders, refresh, wsClient, marketOracles])
}

export const useMarketHours = (market: SupportedMarket) => {
  const { pythFeedId } = MarketMetadata[market]

  return useQuery({
    queryKey: ['marketHours', market],
    refetchInterval: (data) => {
      if (!data || !data.state.data) {
        return false
      }

      const { nextOpen, nextClose } = data.state.data
      if (!nextOpen || !nextClose) return false
      const now = Math.floor(Date.now() / 1000)
      return Math.min(nextOpen - now, nextClose - now) * 1000
    },
    queryFn: async (): Promise<MarketHours> => {
      const req = await fetch(`${PythPriceFeedUrl}/${pythFeedId}`)
      if (req.ok) {
        const { market_hours } = await req.json()
        return {
          isOpen: market_hours.is_open,
          nextOpen: market_hours.next_open,
          nextClose: market_hours.next_close,
        }
      }
      throw new Error("Couldn't fetch market hours")
    },
  })
}

export * from './chain'
export * from './graph'
export * from './tx'
