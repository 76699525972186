export const VaultPocketAbi = [
    {
        "inputs": [
            { "internalType": "address", "name": "vault_", "type": "address" },
            { "internalType": "address", "name": "underlyingToken_", "type": "address" }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    { "inputs": [], "name": "InsufficientFunds", "type": "error" },
    { "inputs": [], "name": "InvalidInitialization", "type": "error" },
    { "inputs": [], "name": "NotInitializing", "type": "error" },
    { "inputs": [], "name": "Unauthorized", "type": "error" },
    { "inputs": [], "name": "ZeroSharesMinted", "type": "error" },
    {
        "anonymous": false,
        "inputs": [
            { "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": false, "internalType": "uint256", "name": "amountUnderlying", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "amountOverlying", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "shares", "type": "uint256" }],
        "name": "Deposit",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "uint64", "name": "version", "type": "uint64" }],
        "name": "Initialized",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            { "indexed": true, "internalType": "address", "name": "user", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "recipient", "type": "address" },
            { "indexed": false, "internalType": "uint256", "name": "amountUnderlying", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "amountOverlying", "type": "uint256" },
            { "indexed": false, "internalType": "uint256", "name": "shares", "type": "uint256" }
        ],
        "name": "Withdrawal",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "OVERLYING_TOKEN",
        "outputs": [{ "internalType": "contract IERC20", "name": "", "type": "address" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [], "name": "UNDERLYING_TOKEN",
        "outputs": [{ "internalType": "contract IERC20", "name": "", "type": "address" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [], "name": "VAULT",
        "outputs": [{ "internalType": "contract IVault", "name": "", "type": "address" }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{ "internalType": "address", "name": "user", "type": "address" }], "name": "balanceOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function"
    }, {
        "inputs": [], "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint256", "name": "amountUnderlying", "type": "uint256" }
        ],
        "name": "registerDeposit",
        "outputs": [{ "internalType": "uint256", "name": "shares", "type": "uint256" }],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "user", "type": "address" }],
        "name": "sharesOf",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalBalance",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalShares",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "version",
        "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            { "internalType": "address", "name": "user", "type": "address" },
            { "internalType": "uint256", "name": "amountUnderlying", "type": "uint256" },
            { "internalType": "address", "name": "recipient", "type": "address" }
        ],
        "name": "withdraw",
        "outputs": [{ "internalType": "uint256", "name": "shares", "type": "uint256" }],
        "stateMutability": "nonpayable",
        "type": "function"
    }
] as const;