import React, { useMemo, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { Container } from "react-bootstrap/esm"
import { ToastContainer } from "react-toastify"
import { useMediaQuery } from "react-responsive"

import Header from "../components/Header";

// version 2
import { useAddress } from "../hooks/network"
import Markets from "../v1/pages/Markets"
import MobileMarkets from "../v1/pages/mobile/MobileMarkets"
import TermsOfServiceModal from "../components/TermsOfService"
import TermsPage from "../v1/pages/TermsPage"
import GovernancePage from "./governance"

import IndexVaultPage from "./indexes"

import TradePage from "./trade"
import { BetaWhitelist } from "../constants/network"
import { WhitelistPage } from "../components/WhitelistPage"
import TradeWrapper from "../components/Trade"
import SpotPage from "./spot"
import IncentivesPage from "./incentives"
import { IncentivesBanner } from "../components/Incentives/components/Banner"
import { useCryptexProductsContext } from "../contexts"


const Main = () => {
  const { address } = useAddress()
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 850px)" });
  const hasTOS = (localStorage.getItem("tosAccepted") === "true");
  const [showTOSModal, setShowTOSModal] = useState<boolean | undefined>(!hasTOS);
  const { isIndexes } = useCryptexProductsContext()

  const isAccountAllowed = useMemo(() => {
    const whitelist = process.env.REACT_APP_WHITELIST ?? "false"
    if (whitelist === "false") return true
    if (!address) return false;

    return BetaWhitelist.map((address) => address.toLowerCase()).includes(address.toLowerCase())
  }, [address])

  return (
    <Container fluid className="wrapper transparent-background">
      <Header />
      {isIndexes() && !window.location.pathname.includes("/terms-of-service") && <IncentivesBanner />}
      {isAccountAllowed ? (
        <>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<IndexVaultPage />} />
            <Route path="/emissions/" element={<IncentivesPage />} />
            <Route path="/spot" element={<SpotPage />} />
            <Route path="/perpetuals" element={<TradePage />} />
            <Route path="/perpetuals/liquidity/" element={<TradeWrapper isProInterface={true} />} />
            <Route path="/governance/" element={<GovernancePage />} />
            
            <Route
              path="v2/:product?"
              element={!isMobileOrTablet ? <Markets /> : <MobileMarkets />}
            />
            <Route path="/terms-of-service" element={<TermsPage />} />
          </Routes>
          <TermsOfServiceModal showModal={showTOSModal} onHide={() => setShowTOSModal(false) } />
        </>
      ) : (
        <WhitelistPage />  
      )}
    </Container>
  )
}

export default Main;