import React, { useMemo }  from "react";
import { Button, Form, Image, InputGroup } from "react-bootstrap/esm";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { TokenMetadata } from "../../../constants/tokens";
import { formatUnits } from "viem";
import { useTokenBalances } from "../../../hooks/wallet";
import { useIndexVaultContext } from "../../../contexts/indexVaultContext";


type props = {
  controlId: string;
  value: string;
  title: string;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (event: string) => void;
  onSetMaxAmount: (amount: bigint) => void;
  deposit: boolean;
  isUpdating?: boolean;
  disabled?: boolean;
}

export const TokenInput = ({
  controlId,
  value,
  title,
  onFocus,
  onBlur,
  onChange,
  onSetMaxAmount,
  deposit,
  isUpdating = false,
  disabled = false,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { selectedVault, currentUserPocketSnapshot, currentVaultSnapshot } = useIndexVaultContext();
  const tokenMetadata = TokenMetadata[selectedVault.collateral];
  const { data: tokensBalances } = useTokenBalances(selectedVault.collateral);

  const { maxValue, maxValueBI } = useMemo(() => {
    if (deposit && tokensBalances) {
      return {
        maxValue: parseFloat(formatUnits(tokensBalances.balance, tokenMetadata.decimals)),
        maxValueBI: tokensBalances.balance,
      };
    } else if (currentUserPocketSnapshot && currentVaultSnapshot) {
      return {
        maxValue: parseFloat(formatUnits(currentUserPocketSnapshot.collateral, currentVaultSnapshot.collateralDecimals)),
        maxValueBI: currentUserPocketSnapshot.collateral - currentUserPocketSnapshot.outstandingInterest,
      }
    }
    return { maxValue: 0, maxValueBI: 0n };
  },
    // eslint-disable-next-line
    [deposit, selectedVault.collateral, tokensBalances]
  );

  return (
    <Form.Group className="asset-input-group" style={{ marginTop: "0.5rem", marginBottom: "0.1rem" }}>
      <div className="asset-input-top mb-1">
        <h6>{title}</h6>
      </div>
      <InputGroup className="input-collateral">
        <Form.Control
          id={controlId}
          placeholder="0"
          className="balance-input"
          value={value}
          type={isMobile ? "number" : "text"}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
          }
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          disabled={disabled}
        />
        <div className="asset-info">
          <div className="asset-info2">
            <Image src={tokenMetadata.icon} height={25} width={25} />
          </div>
          <div className="asset-balance" style={{ marginTop: "0.2rem" }}>
            <span className="title">
              {deposit ? t("balance") : t("available")}:
            </span>
            <span className="balance">{maxValue.toFixed(4)}</span>
            <Button
              variant="secondary"
              className="small"
              onClick={() => onSetMaxAmount(maxValueBI || 0n)}
              disabled={disabled}
            >
              {t("max")}
            </Button>
          </div>
        </div>
      </InputGroup>
    </Form.Group>
  );    
}
