
import React, { createContext, useContext, useEffect, useState } from 'react'
import { base, sepolia } from 'viem/chains';

import { SupportedChainIdType } from "../constants/network"
import { SupportedIndexes } from '../constants/indexes';
import { IndexVaultsMetadata, SupportedPockets, VaultType } from '../constants/vaults';
import {
  UserPocketSnapshotType,
  UserVaultsSnapshotType,
  useUserVaultsSnapshots,
  VaultsSnapshotType,
  VaultPocketSnapshotType,
  useVaultSnapshots,
} from '../hooks/vaults';
import { useChainId } from '../hooks/network';
import { SupportedTokens } from '../constants/tokens';


type IndexVaultContextType = {
  chainId: SupportedChainIdType;
  selectedIndex: SupportedIndexes;
  setSelectedIndex: (asset: SupportedIndexes) => void;
  selectedVault: VaultType;
  setSelectedVault: (vault: VaultType) => void;
  setSelectedVaultByCollateral: (collateral: SupportedTokens) => void;
  selectedPocket: SupportedPockets;
  setSelectedPocket: (pocket: SupportedPockets) => void;
  vaultSnapshots?: VaultsSnapshotType;
  currentVaultSnapshot?: VaultPocketSnapshotType;
  userSnapshots?: UserVaultsSnapshotType;
  currentUserPocketSnapshot?: UserPocketSnapshotType;
}

const IndexVaultContext = createContext<IndexVaultContextType>({
  chainId: sepolia.id,
  selectedIndex: SupportedIndexes.tcap,
  setSelectedIndex: (index: SupportedIndexes) => {},
  selectedVault: IndexVaultsMetadata[sepolia.id][SupportedIndexes.tcap][0],
  setSelectedVault: (vault: VaultType) => { },
  setSelectedVaultByCollateral: (collateral: SupportedTokens) => { },
  selectedPocket: SupportedPockets.default,
  setSelectedPocket: (pocket: SupportedPockets) => { },
  userSnapshots: undefined,
  currentUserPocketSnapshot: undefined,
});

export const IndexVaultProvider = ({ children }: { children: React.ReactNode }) => { 
  const chainId = useChainId();
  const [selectedIndex, _setSelectedIndex] = useState<SupportedIndexes>(SupportedIndexes.tcap);
  const [selectedVault, _setSelectedVault] = useState<VaultType>(IndexVaultsMetadata[base.id][SupportedIndexes.tcap][0]);
  const [selectedPocket, _setSelectedPocket] = useState<SupportedPockets>(SupportedPockets.aave);
  const [currentVaultSnapshot, setCurrentVaultSnapshot] = useState<VaultPocketSnapshotType>();
  const [currentUserPocketSnapshot, setCurrentUserPocketSnapshot] = useState<UserPocketSnapshotType>();
  const { data: vaultSnapshots, refetch: refetchVaults } = useVaultSnapshots();
  const { data: userSnapshots } = useUserVaultsSnapshots();

  useEffect(() => {
    if (vaultSnapshots && vaultSnapshots[selectedIndex]) {
      
      const vaultSnapshot = vaultSnapshots[selectedIndex].find(vault => vault.vaultAddress === selectedVault.address);
      if (vaultSnapshot) {
        setCurrentVaultSnapshot(vaultSnapshot);
      }
    }
  }, [vaultSnapshots, selectedIndex, selectedVault]);

  useEffect(() => {
    if (userSnapshots) {
      const userCurrentVaults = userSnapshots[selectedIndex];
      if (userCurrentVaults) {
        const pocketSnapshot = userCurrentVaults.find(
          vault => vault.vaultAddress === selectedVault.address && vault.pocket === selectedPocket
        );
        if (pocketSnapshot) {
          setCurrentUserPocketSnapshot(pocketSnapshot);
        }
      }
    }
  }, [userSnapshots, selectedIndex, selectedPocket, selectedVault]);

  const setSelectedIndex = (index: SupportedIndexes) => {
    _setSelectedIndex(index);
  }

  const setSelectedVault = (vault: VaultType) => {
    _setSelectedVault(vault);
    refetchVaults();
  }

  const setSelectedVaultByCollateral = (collateral: SupportedTokens) => { 
    const vault = IndexVaultsMetadata[chainId][selectedIndex].find(vault => vault.collateral === collateral);
    if (vault) {
      _setSelectedVault(vault);
      refetchVaults();
    }
  }

  const setSelectedPocket = (pocket: SupportedPockets) => {
    _setSelectedPocket(pocket);
    refetchVaults();
  }

  return (
    <IndexVaultContext.Provider
      value={{
        chainId,
        selectedIndex,
        setSelectedIndex,
        selectedVault,
        setSelectedVault,
        setSelectedVaultByCollateral,
        selectedPocket,
        setSelectedPocket,
        vaultSnapshots,
        currentVaultSnapshot,
        userSnapshots,
        currentUserPocketSnapshot,
      }}
    >
      {children}
    </IndexVaultContext.Provider>
  );
}

export const useIndexVaultContext = () => {
  const context = useContext(IndexVaultContext)
  if (context === undefined) {
    throw new Error('useIndexVaultContext must be used within a IndexVaultProvider')
  }
  return context
}
