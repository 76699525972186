import { useCallback } from "react";
import { useWalletClient, useWriteContract } from "wagmi"
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Address, BaseError, encodeFunctionData } from "viem";

import { useAddress, useChainActions, useChainId } from "../network";
import { errorNotification, useTransactionToasts } from "../../components/common";
import { metamaskTxRejectedError, SupportedChainIdType } from "../../constants/network";
import { addressToVault, SupportedPockets } from "../../constants/vaults";
import { ERC20Abi } from "../../abi/ERC20.abi";
import { VaultAbi } from "../../abi/Vault.abi";
import { VaultTxList } from "../../components/Vaults/constants";


export const useVaultTransactions = () => {
  const { t } = useTranslation()
  const chainId = useChainId()
  const { data: walletClient } = useWalletClient();
  const { writeContractAsync } = useWriteContract();
  const { onSwitchChain } = useChainActions()
  const queryClient = useQueryClient()
  const toasts = useTransactionToasts()
  const { onBuildDeposit, onBuildMint, onBuildBurn, onBuildWithdraw } = useBuildVaultTx(chainId)

  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['IndexesBalances', 'TokenBalances', 'VaultsSnapshot', 'UserVaultsSnapshot'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const refreshBalances = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['IndexesBalances', 'TokenBalances'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const onDepositAndMint = async (vaultAddress: Address, pocket: SupportedPockets, collateralAmount: bigint, indexAmount: bigint) => { 
    const depositPayload = onBuildDeposit(vaultAddress, pocket, collateralAmount)
    const mintPayload = onBuildMint(vaultAddress, pocket, indexAmount)

    if (!walletClient || !depositPayload || !mintPayload) return
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: vaultAddress,
        abi: VaultAbi,
        functionName: "multicall",
        args: [[depositPayload, mintPayload]],
      })

      /* const { request } = await publicClient.simulateContract({
        address: vaultAddress,
        abi: VaultAbi,
        functionName: "multicall",
        args: [[depositPayload, mintPayload]],
        account: walletClient.account.address,
      })
        
      const hash = await walletClient.writeContract(request) */
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: t("notification.index-minted"),
          errorMsg: "Failed to mint",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 5000)
          }
        }
      )
      return true;
    } catch (err) {
      console.log("Mint error: ", err)
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to mint")
        }
      } else {
        errorNotification("Error: Failed to mint")
      }
      return false;
    }
  }

  const onBurnAndWithdraw = async (vaultAddress: Address, pocket: SupportedPockets, collateralAmount: bigint, indexAmount: bigint) => {
    const burnPayload = onBuildBurn(vaultAddress, pocket, indexAmount)
    const withdrawPayload = onBuildWithdraw(vaultAddress, pocket, collateralAmount)
    
    if (!walletClient || !burnPayload || !withdrawPayload) return
    if (!(await onSwitchChain())) return
 
    try {
      const hash = await writeContractAsync({
        address: vaultAddress,
        abi: VaultAbi,
        functionName: "multicall",
        args: [[burnPayload, withdrawPayload]],
      })

      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: t("notification.index-burned"),
          errorMsg: "Failed to burn",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 5000)
          }
        }
      )
      return true;
    } catch (err) {
      console.log("Redeem error: ", err)
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to burn")
        }
      } else {
        errorNotification("Error: Failed to burn")
      }
      return false;
    }
  }

  const onDeposit = async (vaultAddress: Address, pocket: SupportedPockets, amount: bigint) => { 
    await onMakeTransaction(vaultAddress, pocket, VaultTxList.deposit, amount);
  }

  const onMint = async (vaultAddress: Address, pocket: SupportedPockets, indexAmount: bigint) => { 
    await onMakeTransaction(vaultAddress, pocket, VaultTxList.mint, indexAmount);
  }

  const onWithdraw = async (vaultAddress: Address, pocket: SupportedPockets, amount: bigint) => { 
    await onMakeTransaction(vaultAddress, pocket, VaultTxList.withdraw, amount);
  }

  const onBurn = async (vaultAddress: Address, pocket: SupportedPockets, indexAmount: bigint) => { 
    await onMakeTransaction(vaultAddress, pocket, VaultTxList.burn, indexAmount);
  }

  const onMakeTransaction = async (
    vaultAddress: Address,
    pocket: SupportedPockets,
    vaultTx: VaultTxList,
    amount: bigint,
  ) => { 
    const vault = addressToVault(chainId, vaultAddress);
    const currentPocket = vault?.pockets[pocket]

    if (!walletClient || !currentPocket) return
    if (vaultTx === VaultTxList.depositMint || vaultTx === VaultTxList.burnWithdraw) return 
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: vaultAddress,
        abi: VaultAbi,
        functionName: vaultTx,
        args: vaultTx !== VaultTxList.withdraw ? [BigInt(currentPocket.id), amount] : [BigInt(currentPocket.id), amount, walletClient.account.address],
      })

      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: t("notification.index-vault-tx", { tx: vaultTx === VaultTxList.withdraw ? "withdrew" : `${vaultTx}ed`}),
          errorMsg: t("notification.index-vault-failed-tx", { tx: vaultTx }),
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 5000)
          }
        }
      )

      return true;
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification(`Error: Failed to ${vaultTx}`)
        }
      } else {
        errorNotification(`Error: Failed to ${vaultTx}`)
      }
      return false;
    }
  }

  const onApproveCollateral = async (srcToken: Address, amount: bigint, spender: Address) => { 
    if (walletClient === undefined) return
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: srcToken,
        abi: ERC20Abi,
        functionName: "approve",
        args: [spender, amount],
      })
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: t("notification.approved", { token: "Token" }),
          errorMsg: "Failed to approve Token",
          onSuccess: async () => {
            await refreshBalances()
            setTimeout(() => refreshBalances(), 5000)
          }
        }
      )

      return true;
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to approve")
        }
      } else {
        errorNotification("Error: Failed to approve")
      }
      return false;
    }
  }

  return {
    onDeposit,
    onMint,
    onWithdraw,
    onBurn,
    onDepositAndMint,
    onBurnAndWithdraw,
    onApproveCollateral,
  }
}

export const useBuildVaultTx = (chainId: SupportedChainIdType) => { 
  const { address: userAddress } = useAddress()

  const onBuildDeposit = (vaultAddress: Address, currentPocket: SupportedPockets, amount: bigint) => {
    const vault = addressToVault(chainId, vaultAddress);
    const pocket = vault?.pockets[currentPocket]

    if (!pocket) return

    return encodeFunctionData({
      abi: VaultAbi,
      functionName: 'deposit',
      args: [BigInt(pocket.id), amount],
    })      
  }

  const onBuildMint = (vaultAddress: Address, currentPocket: SupportedPockets, indexAmount: bigint) => {
    const vault = addressToVault(chainId, vaultAddress);
    const pocket = vault?.pockets[currentPocket]
    if (!pocket) return

    return encodeFunctionData({
      abi: VaultAbi,
      functionName: 'mint',
      args: [BigInt(pocket.id), indexAmount],
    })      
  }

  const onBuildWithdraw = (vaultAddress: Address, currentPocket: SupportedPockets, amount: bigint) => {
    const vault = addressToVault(chainId, vaultAddress);
    const pocket = vault?.pockets[currentPocket]

    if (!userAddress || !pocket) return

    return encodeFunctionData({
      abi: VaultAbi,
      functionName: 'withdraw',
      args: [BigInt(pocket.id), amount, userAddress],
    }) 
  }

  const onBuildBurn = (vaultAddress: Address, currentPocket: SupportedPockets, indexAmount: bigint) => {
    const vault = addressToVault(chainId, vaultAddress);
    const pocket = vault?.pockets[currentPocket]
    
    if (!pocket) return

    return encodeFunctionData({
      abi: VaultAbi,
      functionName: 'burn',
      args: [BigInt(pocket.id), indexAmount],
    }) 
  }

  return {
    onBuildDeposit,  
    onBuildMint,
    onBuildBurn,
    onBuildWithdraw,
  }    
}