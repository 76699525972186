import React, { useMemo, useState } from "react"
import { Button, Image, Stack, Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from "react-responsive";
import { formatUnits, parseUnits } from "viem";
import { FaXmark } from "react-icons/fa6";

import { IndexInput } from "./IndexInput";
import { IndexTokenList } from "./IndexTokenList";
import { capitalize, isNumbersOnly } from "../../../utils/utils";
import { useChainId } from "../../../hooks/network";
import { SupportedTokens, ChainTokens, TokenMetadata } from "../../../constants/tokens";
import { TokenInput } from "./TokenInput";
import { PocketList, VaultTxLabel } from "./lists";
import { NewVaultMaxAmount, VaultTxList, WithdrawAdjusment } from "../constants";
import { useIndexVaultContext } from "../../../contexts/indexVaultContext";
import { IndexMetadata } from "../../../constants/indexes";
import { useTokenBalances } from "../../../hooks/wallet";
import { useVaultTransactions } from "../../../hooks/vaults/tx";
import { FaExclamationTriangle, FaCheck, FaTimes } from "react-icons/fa";
import { FormNames, VaultStatus, VaultStatusColor } from "../constants";
import { useOnchangeHandlers, useValidators } from "../hooks";
import { CustomTooltip } from "../../common";
import { aavePoolUrl, getStatusFromRatio } from "../utils";
import { SupportedPockets } from "../../../constants/vaults";


export const IndexForm = ({
  isUpdating,
  baseAction,
  canChoosePocket,
  onHideForm
}: {
  isUpdating: boolean,
  baseAction: VaultTxList,
  canChoosePocket: boolean,
  onHideForm: () => void
  }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const chaindId = useChainId();
  const { selectedIndex, selectedVault, selectedPocket, currentVaultSnapshot, currentUserPocketSnapshot } = useIndexVaultContext();
  const collateralMetadata = TokenMetadata[selectedVault.collateral];
  const indexMetadata = IndexMetadata[selectedIndex];
  const { data: tokenBalances } = useTokenBalances(selectedVault.collateral, selectedVault.address);

  const { isNewVault, currentDepositedCollateral, currentMintedAmount, currentRatio, minRatio, collateralDecimals } = useMemo(() => { 
    if (!currentUserPocketSnapshot) return {
      isNewVault: true,
      currentDepositedCollateral: 0n,
      currentMintedAmount: 0n,
      currentRatio: 0,
      minRatio: 0,
      collateralDecimals: TokenMetadata[selectedVault.collateral].decimals,
    };
    const collateralDecimals = currentVaultSnapshot?.collateralDecimals || 6;
    return {
      isNewVault: currentUserPocketSnapshot.collateral <= parseUnits(NewVaultMaxAmount.toString(), collateralDecimals)
        || currentUserPocketSnapshot.mintedAmount <= parseUnits(NewVaultMaxAmount.toString(), indexMetadata.decimals),
      currentDepositedCollateral: currentUserPocketSnapshot.collateral,
      currentMintedAmount: currentUserPocketSnapshot.mintedAmount,
      currentRatio: parseFloat(formatUnits(currentUserPocketSnapshot.healthFactor || 0n, 18)),
      minRatio: parseFloat(formatUnits(currentVaultSnapshot?.liquidationParams.threshold || 0n, 18)),
      collateralDecimals,
    }
  },
    // eslint-disable-next-line
    [currentVaultSnapshot, currentUserPocketSnapshot]
  )

  const [collateralAmount, setCollateralAmount] = useState("");
  const [collateralAmountBI, setCollateralAmountBI] = useState(0n);
  const [indexAmount, setIndexAmount] = useState("");
  const [indexAmountBI, setIndexAmountBI] = useState(0n);

  const setFormValue = (valueName: FormNames, value: string) => { 
    if (valueName === FormNames.collateral) {
      setCollateralAmount(value);
    } else if (valueName === FormNames.indexAmount) { 
      setIndexAmount(value);
    }
  }

  const { currentVaultTx, isDepositing, isBurning, checkAllowance } = useMemo(() => { 
    let currentVaultTx = baseAction;
    if (baseAction === VaultTxList.depositMint) {
      if (collateralAmountBI > 0n && indexAmountBI > 0n) {
        currentVaultTx = VaultTxList.depositMint;
      } else if (collateralAmountBI > 0n) {
        currentVaultTx = VaultTxList.deposit;
      } else if (indexAmountBI > 0n) {
        currentVaultTx = VaultTxList.mint;
      }
    } else if (baseAction === VaultTxList.burnWithdraw) { 
      if (collateralAmountBI > 0n && indexAmountBI > 0n) {
        currentVaultTx = VaultTxList.burnWithdraw;
      } else if (collateralAmountBI > 0n) {
        currentVaultTx = VaultTxList.withdraw;
      } else if (indexAmountBI > 0n) {
        currentVaultTx = VaultTxList.burn;
      }
    }
    
      return {
      currentVaultTx,
      isDepositing: currentVaultTx === VaultTxList.depositMint || currentVaultTx === VaultTxList.deposit,
      isBurning: currentVaultTx === VaultTxList.burnWithdraw || currentVaultTx === VaultTxList.burn,
      checkAllowance: currentVaultTx === VaultTxList.depositMint || currentVaultTx === VaultTxList.deposit,
    }
  }, [baseAction, collateralAmountBI, indexAmountBI])

  const { onChangeCollateral, onChangeIndexAmount } = useOnchangeHandlers({
    setValue: setFormValue,
    collateral: currentDepositedCollateral,
    indexAmount: currentMintedAmount,
    healthFactor: currentVaultSnapshot?.liquidationParams.threshold || 0n,
    healthFactorFixed: false,
    currentVaultSnapshot: currentVaultSnapshot
  })

  const { validateCollateral, validateIndexAmount } = useValidators({
    currentVaultTx,
    collateralBalance: tokenBalances?.balance || 0n,
    currentUserPocketSnapshot,
  })

  const { tokenAllowance } = useMemo(() => { 
    if (!tokenBalances) return { tokenAllowance: 0n, tokenBalance: 0n };

    return {
      tokenAllowance: tokenBalances.allowance,
      tokenBalance: tokenBalances.balance,
    }    
  }, [tokenBalances])

  const { needsApproval } = useMemo(() => {
    if (checkAllowance) {
      return { needsApproval: tokenAllowance < collateralAmountBI }
    }
    return { needsApproval: false };
  }, [tokenAllowance, collateralAmountBI, checkAllowance])

  const { collateralError } = useMemo(() => {
    let collateralError = validateCollateral(collateralAmount);
    if (collateralError === "" ||  isNumbersOnly(collateralAmount)) {
      setCollateralAmountBI(parseUnits(collateralAmount, currentVaultSnapshot?.collateralDecimals || 6));
    }

    return { collateralError }
  }, [validateCollateral, collateralAmount, currentVaultSnapshot])

  const { indexError } = useMemo(() => {
    let indexError = validateIndexAmount(indexAmount);

    if (indexError === "") {
      setIndexAmountBI(parseUnits(indexAmount, indexMetadata.decimals));
    }

    return { indexError }
  }, [validateIndexAmount, indexAmount, indexMetadata])

  const { newDepositedCollateralBI, newMintedAmountBI, newRatio, newVaultStatus } = useMemo(() => { 
    let newDepositedCollateralBI = currentDepositedCollateral;
    let newMintedAmountBI = currentMintedAmount;

    newDepositedCollateralBI = isDepositing ? newDepositedCollateralBI + collateralAmountBI : newDepositedCollateralBI - collateralAmountBI;
    newMintedAmountBI = isBurning ? newMintedAmountBI - indexAmountBI : newMintedAmountBI + indexAmountBI;

    const collateralValue =
      parseFloat(formatUnits(newDepositedCollateralBI, collateralDecimals)) * parseFloat(formatUnits(currentVaultSnapshot?.collateralPrice || 0n, 18));
    const mintedValue =
      parseFloat(formatUnits(newMintedAmountBI, IndexMetadata[selectedIndex].decimals)) * parseFloat(formatUnits(currentVaultSnapshot?.indexPrice || 0n, 18));

    const newRatio = collateralValue / mintedValue;
    const threshold = parseFloat(formatUnits(currentVaultSnapshot?.liquidationParams?.threshold || 0n, 18));

    if (mintedValue > 0) {
      return {
        newDepositedCollateralBI,
        newMintedAmountBI,
        newRatio: newRatio * 100,
        newVaultStatus: getStatusFromRatio(threshold, newRatio),
      }
    } else {
      return {
        newDepositedCollateralBI,
        newMintedAmountBI,
        newRatio: 0,
        newVaultStatus: VaultStatus.safe,
      }
    }

  }, [
    currentDepositedCollateral,
    currentMintedAmount,
    isDepositing,
    isBurning,
    collateralAmountBI,
    indexAmountBI,
    currentVaultSnapshot,
    selectedIndex,
    collateralDecimals,
  ])

  const { txBtnDisabled } = useMemo(() => { 
    let txBtnDisabled = indexError !== "" || collateralError !== "" || newVaultStatus === VaultStatus.error || indexAmountBI === 0n || collateralAmountBI === 0n;
    if (isUpdating) {
      txBtnDisabled = indexError !== "" || collateralError !== "" || newVaultStatus === VaultStatus.error || (indexAmountBI === 0n && collateralAmountBI === 0n);
    }

    return { txBtnDisabled }
  }, [isUpdating, indexError, collateralError, newVaultStatus, indexAmountBI, collateralAmountBI])

  const resetValues = () => { 
    setCollateralAmount("");
    setCollateralAmountBI(0n);
    setIndexAmount("");
    setIndexAmountBI(0n);
  }

  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="w-100 index-vault-form mt-1 align-self-center align-items-center justify-content-center"
    >
      <div
        className="index-form h-100 bg-dark border-primary p-0"
        style={{ width: !isUpdating ? "30%" : "50%" }}
      >
        {!isUpdating && (
          <Stack direction="horizontal" gap={0} className="py-1 justify-content-between" style={{ borderBottom: "0.5px solid #494866"}}>
            <h5 className="ps-2" style={{ color: "#aaa9bf" }} >{t("mint")}</h5>
            <Button variant="outline-primary" onClick={() => onHideForm()} className="pe-2">
              <FaXmark size={24} fill="#aaa9bf" />
            </Button>  
          </Stack>
        )}
        <Stack className={!isMobile ? "p-2" : "p-2"}>
          <>
            <Stack direction="vertical" gap={1} className={!isUpdating ? "mb-2" : "mb-0"}>
              <IndexTokenList />
              <PocketList disabled={!canChoosePocket} />
              <TokenInput
                controlId="token-input"
                value={collateralAmount}
                title={isDepositing ? `${t("collateral-to-deposit")}` : `${t("collateral-to-withdraw")}`}
                onFocus={() => {}}
                onBlur={() => {}}
                onChange={(event: string) => onChangeCollateral(event, !isUpdating)}
                onSetMaxAmount={(amount: bigint) => {
                  setCollateralAmountBI(amount);
                  onChangeCollateral(formatUnits(amount, collateralMetadata.decimals), !isUpdating)
                }}
                deposit={isDepositing}
                isUpdating={isUpdating}
                disabled={!isUpdating && !isNewVault}
              />
              {collateralError && (
                <span className="text-red">{collateralError}</span>
              )}
              <IndexInput
                controlId="settoken-input"
                value={indexAmount}
                title={t("token-amount", { token: "TCAP" })}
                onFocus={() => {}}
                onBlur={() => {}}
                onChange={(event: string) => onChangeIndexAmount(event)}
                onSetMaxAmount={(amount: bigint) => {
                  setIndexAmountBI(amount);
                  setIndexAmount(formatUnits(amount, indexMetadata.decimals));
                }}
                showMax={baseAction === VaultTxList.burnWithdraw}
                disabled={!isUpdating && !isNewVault}
              />
              {indexError && (
                <span className="text-red">{indexError}</span>
              )}
              {isUpdating && baseAction === VaultTxList.burnWithdraw && (
                <Stack
                  direction="horizontal"
                  className="my-1 py-3 px-2 align-items-center justify-content-space-between"
                  style={{
                    backgroundColor: "#0e0e19",
                    border: "0.5px solid #333",
                    borderRadius: "5px",
                  }}
                >
                  <Stack direction="horizontal" className="w-50">
                    <CustomTooltip
                      id="tvinterest"
                      msg={t("tooltip.stability-fee-rate")}
                      showIcon={true}
                      iconOnLeft={true}
                    >
                      <h6 className=" ps-1">{t("fee-to-pay")}:</h6>
                    </CustomTooltip>
                  </Stack>  
                  <Stack direction="horizontal" gap={1} className="w-50 justify-content-end">
                    <h6>{parseFloat(formatUnits(currentUserPocketSnapshot?.outstandingInterest || 0n, collateralDecimals)).toFixed(5)}</h6>
                    <Image src={collateralMetadata.icon} alt={collateralMetadata.name} height={20} width={20} />
                  </Stack>  
                </Stack>
              )}
              {!isUpdating && (
                <>
                  <Stack
                    className="justify-content-space-between align-items-center my-1 p-2 border-box"
                    direction="horizontal"
                    gap={3}
                  >
                    <Stack className="w-40" direction="horizontal" gap={2}>
                      <CustomTooltip
                        id="exliq-price"
                        msg={"A safe ratio indicates that your vault's ratio is at least 25% higher than the minimum required to prevent liquidation."}
                        showIcon={true}
                        iconOnLeft={true}
                        iconSize={11}
                      >
                        <h6 className="margin-left">
                          {t("vault-ratio")}
                        </h6>
                      </CustomTooltip>  
                    </Stack>
                    {indexAmountBI > 0n ? (
                      <Stack className="w-60 justify-content-end" direction="horizontal" gap={2}>
                        <span className="text-right" style={{ color: VaultStatusColor[newVaultStatus] }}>
                          {capitalize(newVaultStatus)} - {newRatio.toFixed(2)}%
                        </span>
                        <StatusIcon status={newVaultStatus} />
                      </Stack>
                    ) : (
                      <Stack className="w-60 justify-content-end" direction="horizontal" gap={2}>
                        <span className="text-right text-muted">
                          N/A
                        </span>
                      </Stack>  
                    )}
                  </Stack>
                  <Stack className="py-2 px-1 border-box">
                    <Stack direction="horizontal" className="justify-content-space-between align-items-center px-2 py-1">
                      <Stack className="w-60" direction="horizontal" gap={2}>
                        <CustomTooltip
                          id="exliq-price"
                          msg={t("tooltip.min-ratio")}
                          showIcon={true}
                          iconOnLeft={true}
                          iconSize={11}
                        >
                          <h6 className="margin-left">{t("min-ratio")}:</h6>
                        </CustomTooltip>
                      </Stack>
                      <h6 className="w-40 text-right">
                        {minRatio * 100}%
                      </h6>
                    </Stack>
                    <Stack
                      className="justify-content-space-between align-items-center px-2 py-1"
                      direction="horizontal"
                      gap={1}
                    >
                      <Stack className="w-60" direction="horizontal" gap={2}>
                        <CustomTooltip
                          id="exliq-price"
                          msg={t("tooltip.stability-fee-rate")}
                          showIcon={true}
                          iconOnLeft={true}
                          iconSize={11}
                        >
                          <h6 className="ms-1">{t("stability-fee-rate")}:</h6>
                        </CustomTooltip>  
                      </Stack>
                      <Stack direction="horizontal" gap={2} className="w-40 justify-content-end">
                        <h6 className="text-right">
                          {(currentVaultSnapshot?.interestRate || 0) / 100}%
                        </h6>
                      </Stack>  
                    </Stack>
                    <Stack direction="horizontal" className="justify-content-space-between align-items-center px-2 py-1">
                      <Stack className="w-60" direction="horizontal" gap={2}>
                        <CustomTooltip
                          id="exliq-price"
                          msg={
                            <span>
                              {t("tooltip.aave-apy")}{" "}
                              <a href={aavePoolUrl(chaindId, selectedVault.collateral)} target="_blank" rel="noreferrer">Aave pool</a>
                            </span>
                          }
                          hideDelay={600}
                          showIcon={true}
                          iconOnLeft={true}
                          iconSize={11}
                        >
                          <h6 className="ms-1">Aave APY:</h6>
                        </CustomTooltip>  
                      </Stack>
                      <Stack direction="horizontal" gap={2} className="w-40 justify-content-end">
                        {selectedPocket === SupportedPockets.aave ? (
                          <h6 className="text-right text-green">
                            {currentVaultSnapshot?.aaveApy ? (
                              (currentVaultSnapshot.aaveApy * 100).toFixed(2)
                            ) : (
                              "-"
                            )}%
                          </h6>
                        ) : (
                          <span className="text-muted">N/A</span>  
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
            {!isUpdating && (
              <>
                <TxButton
                  currentVaultTx={currentVaultTx}
                  indexAmountBI={indexAmountBI}
                  collateralAmountBI={collateralAmountBI}
                  outstandingInterest={currentUserPocketSnapshot?.outstandingInterest || 0n}
                  disabled={txBtnDisabled || !isNewVault}
                  needsApproval={needsApproval}
                  onTxFinished={resetValues}
                />
              </>  
            )}  
          </>
        </Stack>
      </div>
      {isUpdating && (
        <Stack
          direction="vertical"
          gap={2}
          className={`${!isMobile ? "w-45" : "w-100"} pb-2 update-vault-info jutify-content-space-bettween`}
        >
          <Stack className="info-top py-3 px-2">
            <Stack direction="horizontal" className="mb-2 px-3">
              <Stack className="w-34" />
              <Stack className="w-33 text-right">
                <h6 className="small text-muted">{t("current-value")}</h6>
              </Stack>
              <Stack className="w-33 text-right">
                <h6 className="small text-muted">{t("new-value")}</h6>
              </Stack>
            </Stack>
            <Stack
              className="align-items-center py-3 px-3 mb-2"
              direction="horizontal"
              style={{
                backgroundColor: "#0e0e19",
                border: "0.5px solid #333",
                borderRadius: "5px",
              }}
            >
              <Stack className="w-34">
                <h6 className="text-muted">{t("collateral")}</h6>
              </Stack>
              <Stack className="w-33 text-right">
                <h6>
                  {parseFloat(formatUnits(currentDepositedCollateral, collateralDecimals)).toFixed(4)}
                </h6>
              </Stack>
              <Stack className="w-33 text-right">
                {collateralAmountBI > 0n ? (
                  <h6 className="text-green">
                    {parseFloat(formatUnits(newDepositedCollateralBI, collateralDecimals)).toFixed(4)}
                  </h6>
                ) : (
                  <h6 className="text-muted">-</h6>
                )}
              </Stack>
            </Stack>
            <Stack direction="horizontal" className="align-items-center py-3 px-3 mb-2 border-box-light">
              <Stack className="w-34">
                <h6 className="text-muted">{t("minted")}</h6>
              </Stack>
              <Stack className="w-33 text-right">
                <h6>
                  {parseFloat(formatUnits(currentMintedAmount, indexMetadata.decimals)).toFixed(4)}
                </h6>
              </Stack>
              <Stack className="w-33 text-right">
                {indexAmountBI > 0n ? (
                  <h6 className="text-green">
                    {parseFloat(formatUnits(newMintedAmountBI, indexMetadata.decimals)).toFixed(4)}
                  </h6>
                ) : (
                  <h6 className="text-muted">-</h6>
                )}
              </Stack>
            </Stack>
            <Stack direction="horizontal" className="align-items-center border-box py-3 px-3">
              <Stack className="w-34">
                <h6 className="text-muted">{t("ratio")}</h6>
              </Stack>
              <Stack className="w-33 text-right">
                {currentUserPocketSnapshot && currentUserPocketSnapshot.mintedAmount > 0n ? (
                  <h6 style={{ color: VaultStatusColor[getStatusFromRatio(minRatio, currentRatio)] }}>
                    {(currentRatio * 100).toFixed(1)}%
                  </h6>
                ) : (
                  <h6 className="text-muted">-</h6>
                )}
              </Stack>
              <Stack className="w-33 text-right">
                {collateralAmountBI > 0n || indexAmountBI > 0n ? (
                  <Stack direction="horizontal" gap={1} className="justify-content-end">
                    <StatusIcon status={newVaultStatus} size={14} />
                    <h6 style={{ color: VaultStatusColor[newVaultStatus] }}>
                      {newRatio.toFixed(1)}%
                    </h6>
                  </Stack>
                ) : (
                  <h6 className="text-muted">-</h6>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack className="info-bottom px-2">
            <TxButton
              currentVaultTx={currentVaultTx}
              indexAmountBI={indexAmountBI}
              collateralAmountBI={collateralAmountBI}
              outstandingInterest={currentUserPocketSnapshot?.outstandingInterest || 0n}
              disabled={txBtnDisabled}
              needsApproval={needsApproval}
              onTxFinished={() => {
                resetValues();
                onHideForm();
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

const TxButton = ({
  currentVaultTx,
  indexAmountBI,
  collateralAmountBI,
  outstandingInterest,
  disabled,
  needsApproval,
  onTxFinished,
}: {
  currentVaultTx: VaultTxList,
  indexAmountBI: bigint,
  collateralAmountBI: bigint,
  outstandingInterest: bigint,
  disabled: boolean,
  needsApproval: boolean,
  onTxFinished: () => void,
}) => {
  const { t } = useTranslation()
  const { selectedVault, selectedPocket } = useIndexVaultContext();
  const tokenMetadata = TokenMetadata[selectedVault.collateral];
  const { onDepositAndMint, onDeposit, onMint, onBurnAndWithdraw, onBurn, onWithdraw } = useVaultTransactions();
  const [writingTx, setWritingTx] = useState(false);

  const onBtnClick = async () => {
    setWritingTx(true);
    if (currentVaultTx === VaultTxList.depositMint) {
      await onDepositAndMint(selectedVault.address, selectedPocket, collateralAmountBI, indexAmountBI)
    } else if (currentVaultTx === VaultTxList.deposit) {
      await onDeposit(selectedVault.address, selectedPocket, collateralAmountBI)
    } else if (currentVaultTx === VaultTxList.mint) {
      await onMint(selectedVault.address, selectedPocket, indexAmountBI)
    } else if (currentVaultTx === VaultTxList.burnWithdraw) {
      const interest = outstandingInterest > 0n ? outstandingInterest + parseUnits(WithdrawAdjusment[selectedVault.collateral], tokenMetadata.decimals) : 0n;
      const collateralWithouthInterest = collateralAmountBI - interest;
      await onBurnAndWithdraw(selectedVault.address, selectedPocket, collateralWithouthInterest, indexAmountBI)
    } else if (currentVaultTx === VaultTxList.withdraw) {
      const interest = outstandingInterest > 0n ? outstandingInterest + parseUnits(WithdrawAdjusment[selectedVault.collateral], tokenMetadata.decimals) : 0n;
      const collateralWithouthInterest = collateralAmountBI - interest;
      await onWithdraw(selectedVault.address, selectedPocket, collateralWithouthInterest)
    } else if (currentVaultTx === VaultTxList.burn) { 
      await onBurn(selectedVault.address, selectedPocket, indexAmountBI)
    }
    setWritingTx(false);
    onTxFinished();
  }

  return ( 
    <Stack direction="vertical" className="justify-content-end">
      {!needsApproval ? (
        <Button
          variant="primary"
          style={{ width: "100%" }}
          onClick={() => onBtnClick()}
          disabled={disabled || writingTx}
        >
          <div className="btn-spinner">
            {writingTx && <Spinner animation="border" variant="secondary" className="small" />}
            {t(VaultTxLabel(currentVaultTx))}
          </div>
        </Button>
      ) : (
        <ApproveButton collateralToken={selectedVault.collateral} amount={collateralAmountBI} disabled={!needsApproval} />  
      )}
    </Stack>  
  )
}

const ApproveButton = ({
  collateralToken,
  amount,
  disabled
}: {
  collateralToken: SupportedTokens,
  amount: bigint,
  disabled: boolean,
}) => {
  const { t } = useTranslation()
  const chainId = useChainId();
  const { selectedVault} = useIndexVaultContext();
  const { onApproveCollateral } = useVaultTransactions();
  const [approving, setApproving] = useState(false);

  const { tokenAddress } = useMemo(() => { 
    return { tokenAddress: ChainTokens[chainId][collateralToken] }
  }, [chainId, collateralToken])

  const handleOnClick = async () => { 
    setApproving(true);
    await onApproveCollateral(tokenAddress, amount, selectedVault.address)
    setApproving(false);
  }

  return (
    <Button
      className="btn btn-primary w-100"
      onClick={() => handleOnClick()}
      disabled={approving || disabled}
    >
      <div className="btn-spinner">
        {approving && <Spinner animation="border" variant="secondary" className="small" />}
        {approving ? (
          `${t("approving", { token: collateralToken.toUpperCase()})}`
        ) : (
          `${t("approve", { token: collateralToken.toUpperCase()})}`
        )}
      </div>
    </Button>
  )
}

const StatusIcon = ({ status, size = 18 }: { status: VaultStatus, size?: number }) => { 
  if (status === VaultStatus.safe) {
    return <FaCheck size={size} fill={VaultStatusColor[status]} />
  } else if (status === VaultStatus.danger) {
    return <FaExclamationTriangle size={size} fill={VaultStatusColor[status]} />
  } else {
    return <FaTimes size={size} fill={VaultStatusColor[status]} />
  }
}