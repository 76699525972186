import React, { useState } from "react"
import { Button, Dropdown, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Big6Math, formatBig6Percent, calcFundingRates } from "@perennial/sdk"

import { useFormattedMarketBarValues } from "../Trade/metrics/hooks"
import { useMarketContext } from "../../contexts"
import { useMediaQuery } from "react-responsive"
import { FaCaretDown } from "react-icons/fa"
import { MarketInfoButton } from "./MarketInfo"
import { CustomTooltip } from "./Tooltip"


export const MarketInfoMetrics = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })

  return (
    <Stack direction="horizontal" gap={!isMobile ? 2 : 0} className="market-bar mb-2">
      {isMobile && <MarketInfoButton />}
      <MarketMetrics />
    </Stack>
  )
}

export const MarketMetrics = () => {
  const { t } = useTranslation()
  const { isMaker } = useMarketContext()
  const marketValues = useFormattedMarketBarValues()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const [showMetrics, setShowMetrics] = useState(!isMobile)

  return (
    <Stack direction="horizontal" gap={3} className="market-metrics">
      {isMobile && (
        <Button className="btn-market-metrics" active={showMetrics} onClick={() => setShowMetrics(!showMetrics)}>
          <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
            <span className="text-muted xs">{t("metrics.metrics")}</span>
            <FaCaretDown size={18} />
          </Stack>
        </Button>
      )}
      {showMetrics && (
        <Stack direction={!isMobile ? "horizontal" : "vertical"} gap={2} className="market-metrics-container">
          {isMobile && (
            <MetricBox
              label={t("metrics.24h-price-change")}
              value={marketValues.change}
              valueColor={marketValues.changeIsNegative ? "text-red" : "text-green"} />
          )}
          {!isMaker ? (
            <>
              <MetricBox label={t("metrics.24h-low")} value={marketValues.low} />
              <MetricBox label={t("metrics.24h-high")} value={marketValues.high} />
              <MetricBox label={t("metrics.volume")} value={marketValues.volume} />
            </>
          ) : (
            <>
              <MetricBox label={t("metrics.7d-volume")} value={marketValues.volume7d} />
              <MetricBox label={t("metrics.lp-exposure")} value={`${marketValues.lpExposurePct} ${marketValues.lpExposure}`} />
            </>
          )}
          <FundingRateMetrics />
          <MetricBox
            label={t("metrics.ls-skew")}
            value={marketValues.longSkew}
            valueColor="text-green"
            value2={marketValues.shortSkew}
            value2Color="text-red"
          />
          <MetricBox label={t("metrics.open-interest")} value={marketValues.openInterest} />
          <MetricBox label={t("available-liquidity")} value={marketValues.availableLiquidity} />
        </Stack>
      )}
    </Stack>
  )
}

const MetricBox = ({
  label,
  value,
  valueColor,
  value2,
  value2Color
}: {
  label: string,
  value: string,
  valueColor?: string,
  value2?: string,
  value2Color?: string,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  
  return (
    <div className="market-metrics-box info-row">
      <div className="market-metrics-value">
        <h6 className={valueColor}>{value}</h6>
        {value2 && (
          <>
            <h6 className="number">/</h6>
            <h6 className={value2Color}>{value2}</h6>
          </>
        )}
      </div>
      <span className={"text-muted ".concat(!isMobile ? "small" : "")}>{label}</span>
    </div>
  )
}

enum FundingRateTimeFrames {
  hourlyFunding = 'hourlyFunding',
  eightHourFunding = 'eightHourFunding',
  dailyFunding = 'dailyFunding',
  yearlyFunding = 'yearlyFunding',
}

const TimeFrameLabels = {
  [FundingRateTimeFrames.hourlyFunding]: { short: '1H', long: 'metrics.hourly' },
  [FundingRateTimeFrames.eightHourFunding]: { short: '8H', long: 'metrics.8-hours' },
  [FundingRateTimeFrames.dailyFunding]: { short: '24H', long: 'metrics.daily' },
  [FundingRateTimeFrames.yearlyFunding]: { short: '1Y', long: 'metrics.yearly' }
}

const FundingRateMetrics = () => {
  const { t } = useTranslation()
  const { selectedMarketSnapshot2: snapshot, isMaker } = useMarketContext()
  const timeFrames = Object.keys(FundingRateTimeFrames) as FundingRateTimeFrames[]
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<FundingRateTimeFrames>(
    isMaker ? FundingRateTimeFrames.yearlyFunding : FundingRateTimeFrames.hourlyFunding,
  )
  const longRate = calcFundingRates(snapshot?.fundingRate?.long)
  const shortRate = calcFundingRates(snapshot?.fundingRate?.short)
  const makerRate = calcFundingRates(snapshot?.fundingRate?.maker)
  const longSymbol = longRate[selectedTimeFrame] < 0n ? "+" : "-"
  const shortSymbol = shortRate[selectedTimeFrame] < 0n ? "+" : "-"
  const makerSymbol = makerRate[selectedTimeFrame] < 0n ? "+" : "-"
  const longColor = longRate[selectedTimeFrame] < 0n ? "text-green" : "text-red"
  const shortColor = shortRate[selectedTimeFrame] < 0n ? "text-green" : "text-red"
  const makerColor = makerRate[selectedTimeFrame] < 0n ? "text-green" : "text-red"
  const longTooltip = `Longs ${longRate[selectedTimeFrame] < 0n ? "receive" : " pay"} funding`
  const shortTooltip = `Shorts ${shortRate[selectedTimeFrame] < 0n ? "receive" : " pay"} funding`

  return (
    <div className="market-metrics-box info-row">
      <div className="market-metrics-value">
        {!isMaker ? (
          <CustomTooltip
            id={`funding-rate-tooltip-${selectedTimeFrame}`}
            msg={
              <Stack direction="vertical">
                <p className={longColor}>{longTooltip}</p>
                <p className={shortColor}>{shortTooltip}</p>
              </Stack>
            }
            iconSize={13}
            iconColor="#aaa9bf"
            showIcon={true}
          >
            <Stack direction="horizontal" className="me-1">
              <h6 className={longColor}>
                {longSymbol}{formatBig6Percent(Big6Math.abs(longRate[selectedTimeFrame]), { numDecimals: 4 })}
              </h6>
              <h6 className="number">/</h6>
              <h6 className={shortColor}>
                {shortSymbol}{formatBig6Percent(Big6Math.abs(shortRate[selectedTimeFrame]), { numDecimals: 4 })}
              </h6>
            </Stack>
          </CustomTooltip>  
        ) : (
          <h6 className={makerColor}>
            {makerSymbol}{formatBig6Percent(Big6Math.abs(makerRate[selectedTimeFrame]), { numDecimals: 4 })}    
          </h6>   
        )}
      </div>
      <Stack direction="horizontal" gap={1}>
        <span className="small text-muted">{t("metrics.ls-funding-rate")}</span>
        <div>
          <Dropdown
            className="funding-dropdown"
            onSelect={(eventKey) => setSelectedTimeFrame(eventKey as FundingRateTimeFrames ?? FundingRateTimeFrames.hourlyFunding)}
            style={{ position: "absolute", width: "2.5rem", marginTop: "-9px", marginLeft: "2px" }}
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <span className="text-muted">{TimeFrameLabels[selectedTimeFrame].short}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {timeFrames.map((timeFrame) => {
                return (
                  <Dropdown.Item key={timeFrame} eventKey={timeFrame}>
                    <Stack direction="horizontal" className="flex-row space-between">
                      <span className="text-muted small">{t(TimeFrameLabels[timeFrame].long)}</span>
                      {!isMaker ? (
                        <Stack direction="horizontal">
                          <span className={longColor}>
                            {formatBig6Percent(Big6Math.abs(longRate[timeFrame]), { numDecimals: 4 })}
                          </span>
                          <span className="number text-muted small">/</span>
                          <span className={shortColor}>
                            {formatBig6Percent(Big6Math.abs(shortRate[timeFrame]), { numDecimals: 4 })}
                          </span>
                        </Stack>
                      ) : (
                        <span className={makerColor}>
                          {formatBig6Percent(Big6Math.abs(makerRate[timeFrame]), { numDecimals: 4 })}
                        </span>
                      )}
                    </Stack>
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>  
      </Stack>
    </div>
  )
}
