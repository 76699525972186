import { Address, getAddress, getContract, WalletClient, zeroAddress } from "viem";
import {
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  mainnet,
  sepolia,
} from "viem/chains";
import { SupportedChainId as PerennialSupportedChainId } from "@perennial/sdk"
import { getPublicClient, SupportedChainIdType } from "./network";

import { AaveV3PoolAbi } from '../abi/AaveV3Pool.abi'


export type AddressMappingType = { [chain in SupportedChainIdType]: Address };

type AddressMappingPerennial = { [chain in PerennialSupportedChainId]: Address };


export const CtxTokenAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0x321C2fE4446C7c963dc41Dd58879AF648838f98D'),
  [sepolia.id]: zeroAddress,
  [arbitrum.id]: zeroAddress,
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: getAddress('0xBB22Ff867F8Ca3D5F2251B4084F6Ec86D4666E14'),
  [baseSepolia.id]: zeroAddress,
}

export const SeasonalMerkleTreeAddresses: AddressMappingPerennial = {
  [arbitrum.id]: getAddress('0x4Fc1cC62E5d0b12f5a9e3213B4177d2edb301F30'),
  [arbitrumSepolia.id]: zeroAddress, // TODO: add address
}

export const ChainalysisContractAddress = '0x40c57923924b5c5c5455c48d93317139addac8fb'


export const UsdcAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'),
  [sepolia.id]: getAddress('0xD6ca950E3BC2991186d8d589bCA9947e4d67d594'),
  [arbitrum.id]: getAddress("0xaf88d065e77c8cC2239327C5EDb3A432268e5831"),
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: getAddress('0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'),
  [baseSepolia.id]: getAddress('0xf116c015B28595010261D494C6093be4A2DA6389'),
}

export const WethAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
  [sepolia.id]: getAddress('0x1E057193AC3f482E6794862a2EFfeB9FD0DbBD74'),
  [arbitrum.id]: getAddress("0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"),
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: getAddress('0x4200000000000000000000000000000000000006'),
  [baseSepolia.id]: getAddress('0x4200000000000000000000000000000000000006'),
}

export const BtcAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
  [sepolia.id]: getAddress('0x9c96C544b225d2d94259dc56C64b05ca45D875Db'),
  [arbitrum.id]: zeroAddress,
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: zeroAddress,
  [baseSepolia.id]: getAddress('0x7d328adff97228542d7F69e09855A020A243C0cF'),
}

export const WethOracleAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419'),
  [sepolia.id]: getAddress('0x48Cbd9Ab411f1C056fD77C274eab7127D481c26f'),
  [arbitrum.id]: getAddress('0xB1f70A229FE7cceD0428245db8B1f6C48c7Ea82a'),
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: getAddress('0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70'),
  [baseSepolia.id]: getAddress('0xBF9533405eA317FB0861f9386F714Bd139a3fdfa'),
}

export const BtcOracleAddresses: AddressMappingType = {
  [mainnet.id]: zeroAddress,
  [sepolia.id]: getAddress('0x1a7Ab9Cf14F1997845A91cF2f549753BA9A1496e'),
  [arbitrum.id]: zeroAddress,
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: zeroAddress,
  [baseSepolia.id]: getAddress('0x7d328adff97228542d7F69e09855A020A243C0cF'),
}

export const FlokiTaxHandlerAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0x834F96fD4fE9147a2a647D957FBbE67FEc62B67b'),
  [sepolia.id]: getAddress('0x834F96fD4fE9147a2a647D957FBbE67FEc62B67b'),
  [arbitrum.id]: zeroAddress,
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: zeroAddress,
  [baseSepolia.id]: zeroAddress,
}

export const UniswapV2RouterAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'),
  [sepolia.id]: getAddress('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'),
  [arbitrum.id]: zeroAddress,
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: zeroAddress,
  [baseSepolia.id]: zeroAddress,
}

export const AaveV3PoolAddresses: AddressMappingType = {
  [mainnet.id]: getAddress('0x87870Bca3F3fD6335C3F4ce8392D69350B4fA4E2'),
  [sepolia.id]: getAddress('0x6Ae43d3271ff6888e7Fc43Fd7321a503ff738951'),
  [arbitrum.id]: getAddress('0x794a61358D6845594F94dc1DB02A252b5b4814aD'),
  [arbitrumSepolia.id]: zeroAddress, 
  [base.id]: getAddress('0xA238Dd80C259a72e81d7e4664a9801593F98d1c5'),
  [baseSepolia.id]: getAddress('0x07eA79F68B2B3df564D0A34F8e19D9B1e339814b'),
}

export const getAaveV3PoolContract = (chainId: SupportedChainIdType, signer?: WalletClient) => { 
  const publicClient = getPublicClient(chainId)
  const poolAddress = AaveV3PoolAddresses[chainId]

  return getContract({
    address: poolAddress,
    abi: AaveV3PoolAbi,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}
