import { Address, getAddress, getContract, WalletClient } from 'viem';
import { getPublicClient, SupportedChainIdType } from "./network";
import { arbitrum, arbitrumSepolia, base, baseSepolia, mainnet, sepolia } from 'viem/chains';

import { IndexAbi } from '../abi/Index.abi'
import { VaultAbi } from '../abi/Vault.abi'
import { VaultPocketAbi } from '../abi/VaultPocket.abi'
import { ChainIndex, SupportedIndexes } from './indexes';
import { SupportedTokens } from './tokens';


export enum SupportedVault { 
  tcapUsdc = 'tcapUsdc',
}

export enum SupportedPockets { 
  aave = 'aave',
  default = 'default',
}


export type PocketsMetadataType = {
  [pocket in SupportedPockets]: {
    id: number
    address: Address
  } | undefined
}

export type VaultType = {
  address: Address
  collateral: SupportedTokens
  pockets: PocketsMetadataType
}

export type IndexVaultsMetadataType = {
  [chainId in SupportedChainIdType]: {
    [index in SupportedIndexes]: VaultType[]
  }
};

export const IndexVaultsMetadata: IndexVaultsMetadataType = {
  [mainnet.id]: {
    [SupportedIndexes.meem]: [],
    [SupportedIndexes.tcap]: [],
  },
  [sepolia.id]: {
    [SupportedIndexes.meem]: [],
    [SupportedIndexes.tcap]: [
      {
        address: getAddress('0x921BFC7bb73D4B28bD06D944072B7F4437c5DAF7'),
        collateral: SupportedTokens.usdc,
        pockets: {
          [SupportedPockets.default]: {
            id: 2,
            address: getAddress('0x09bE3D91CB4DD9Ca5a24Ffd13f3dB7A9f059C2D5')
          },
          [SupportedPockets.aave]: {
            id: 1,
            address: getAddress('0xf69834897e3617a87ecd58a7999e422FF0E3915C')
          },
        }
      }
    ]
  },
  [base.id]: {
    [SupportedIndexes.meem]: [],
    [SupportedIndexes.tcap]: [
      {
        address: getAddress('0x1857e926BB5e5b12e9275818B03F79cdfd799999'),
        collateral: SupportedTokens.usdc,
        pockets: {
          [SupportedPockets.default]: {
            id: 1,
            address: getAddress('0xb0fC91A11d770bD7a6312944199E3a9De85057A3')
          },
          [SupportedPockets.aave]: {
            id: 2,
            address: getAddress('0x9a0A963ce5CD1C9e5Ef5df862b42143E82f6412C')
          },
        },
      },
      {
        address: getAddress('0x4F94C14440ef38B7e551CCFB7A2ce4E464E20F14'),
        collateral: SupportedTokens.weth,
        pockets: {
          [SupportedPockets.default]: {
            id: 1,
            address: getAddress('0x75E78d6659BBc2c1599464e153607a21947A466B')
          },
          [SupportedPockets.aave]: {
            id: 2,
            address: getAddress('0x134ba1c4E7443fC5c0bE8Ca675DF0bE611bd589C')
          },
        }
      },
      {
        address: getAddress('0xA6afc2be04a1c2ED8bEC7F924307b6254fAFF750'),
        collateral: SupportedTokens.cbbtc,
        pockets: {
          [SupportedPockets.default]: {
            id: 1,
            address: getAddress('0x806c81138A52524Ce218E3C6C19873F53334BF94')
          },
          [SupportedPockets.aave]: {
            id: 2,
            address: getAddress('0xCf041E8c66785c7BC41284C66b8aDe5A02094a6e')
          },
        }
      },
      {
        address: getAddress('0xD29D6E24946a8e9B55797F5A4EF34EEB0E73a15A'),
        collateral: SupportedTokens.lbtc,
        pockets: {
          [SupportedPockets.default]: {
            id: 1,
            address: getAddress('0x447B9948464593e1235601157063c495b115e02e')
          },
          [SupportedPockets.aave]: undefined
        }
      }
    ],
  },
  [baseSepolia.id]: {
    [SupportedIndexes.meem]: [],
    [SupportedIndexes.tcap]: [
      {
        address: getAddress('0x62beb4f28f70cF7E4d5BCa54e86851b12AeF2d48'),
        collateral: SupportedTokens.usdc,
        pockets: {
          [SupportedPockets.default]: {
            id: 1,
            address: getAddress('0x57285063Bf804df7f163805D97FcE91Bac52f5E2')
          },
          [SupportedPockets.aave]: {
            id: 2,
            address: getAddress('0xAF56c4266397d87EC2A04269484f50F42EB650CD')
          },
        },
      },
      {
        address: getAddress('0x678295F27e8523cd437326DB9D2875aD7B6B991d'),
        collateral: SupportedTokens.weth,
        pockets: {
          [SupportedPockets.default]: {
            id: 1,
            address: getAddress('0x8E89Ac6a61C3945f60a17a1B03c77c3F5e0fcaAE')
          },
          [SupportedPockets.aave]: {
            id: 2,
            address: getAddress('0x415A187e741564d3EE4aCa4E31a003064c0a4b9f')
          },
        },
      }
    ],
  },
  [arbitrum.id]: {
    [SupportedIndexes.meem]: [],
    [SupportedIndexes.tcap]: [],
  },
  [arbitrumSepolia.id]: {
    [SupportedIndexes.meem]: [],
    [SupportedIndexes.tcap]: [],
  },
}

export const addressToVault = (chainId: SupportedChainIdType, address: Address) => { 
  for (const index of Object.keys(IndexVaultsMetadata[chainId])) {
    for (const vault of IndexVaultsMetadata[chainId][index as SupportedIndexes]) {
      if (vault.address === address) {
        return vault
      }
    }
  }
}

export const getVaultContract = (chainId: SupportedChainIdType, address: Address, signer?: WalletClient) => { 
  const publicClient = getPublicClient(chainId)

  return getContract({
    address,
    abi: VaultAbi,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}

export const getVaultPocketContract = (chainId: SupportedChainIdType, address: Address, signer?: WalletClient) => { 
  const publicClient = getPublicClient(chainId)

  return getContract({
    address,
    abi: VaultPocketAbi,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}

export const getIndexContract = (chainId: SupportedChainIdType, index: SupportedIndexes, signer?: WalletClient) => { 
  const publicClient = getPublicClient(chainId)
  const address = ChainIndex[chainId][index]?.token 

  if (address === undefined) return

  return getContract({
    address,
    abi: IndexAbi,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}