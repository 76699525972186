import { Big18Math, Big6Math, formatBig18USDPrice, formatBig6USDPrice } from '@perennial/sdk';
import { Big8Math, formatBig8USDPrice } from './big8Math';
import { Big9Math } from './big9Math';


export const BigOrZero = (value: number | bigint | string | undefined | null): bigint => {
  return BigInt(value ?? 0)
}

export const formatBigInt = (
  value: bigint = 0n,
  decimals: number = 18,
  {
    numSigFigs = 2,
    useGrouping = true,
    minDecimals,
  }: { numSigFigs?: number; useGrouping?: boolean | undefined; minDecimals?: number } = {},
) => {
  const unsafeFloat =
    decimals === 18 ? Big18Math.toUnsafeFloat(value) :
      (decimals === 8 ? Big8Math.toUnsafeFloat(value) :
      (decimals === 9 ? Big9Math.toUnsafeFloat(value)  : Big6Math.toUnsafeFloat(value)))
  
  return Intl.NumberFormat('en-US', {
    minimumSignificantDigits: numSigFigs,
    maximumSignificantDigits: numSigFigs,
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: minDecimals,

    useGrouping,
    // @ts-ignore
    roundingPriority: 'morePrecision',
  }).format(unsafeFloat)
}

export const formatBigIntUSDPrice = (
  value: bigint = 0n,
  decimals: number = 18,
  {
    compact = false,
    fromUsdc = false,
    fullPrecision = false,
  }: { compact?: boolean; fromUsdc?: boolean; fullPrecision?: boolean } = {},
) => { 

  if (decimals === 18) {
    return formatBig18USDPrice(value, { compact, fromUsdc })
  } else if (decimals === 8) {
    return formatBig8USDPrice(value, { compact, fromUsdc, fullPrecision })
  }
  return formatBig6USDPrice(value, { compact, fromUsdc, fullPrecision })
}

export const formatBigIntPercent = (
  value: bigint = 0n,
  decimals: number = 18,
  { numDecimals = 2 }: { numDecimals?: number } = {}
) => {

  const unsafeFloat =
    decimals === 18 ? Big18Math.toUnsafeFloat(value) :
      (decimals === 8 ? Big8Math.toUnsafeFloat(value) : Big6Math.toUnsafeFloat(value))
  
  return Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  }).format(unsafeFloat)
}
