import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { Fuul } from "@fuul/sdk";
import { useMediaQuery } from "react-responsive";

import { useAddress } from "../../../hooks/network";
import { CtxTokenAddresses } from "../../../constants/contracts";
import { base } from "viem/chains";
import { shortenAddress } from "../../../utils/addressUtils";
// import CtxIcon from '../../../assets/ctx-icon.png';


export const PointsProgram = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  Fuul.init({ apiKey: process.env.REACT_APP_FUUL_API_KEY || "" });
  const { address: userAddress } = useAddress();
  const [userPoins, setUserPoints] = useState({ rank: 0, points: 0 });
  const [leaderboard, setLeaderboard] = useState<Array<any>>([]);
  

  useEffect(() => {
    const load = async () => {
      const ctxAddress = CtxTokenAddresses[base.id];
      const points = await Fuul.getPointsLeaderboard({ currency_address: ctxAddress.toLowerCase(), page: 1, page_size: 10 });
      setLeaderboard(points.results);

      if (userAddress) {
        const userPonts = await Fuul.getPointsLeaderboard({
          user_address: userAddress,
          currency_address: ctxAddress.toLowerCase()
        });
        if (userPonts.results.length > 0) {
          setUserPoints({ rank: userPonts.results[0].rank, points: userPonts.results[0].total_attributions });
        }
      }  
    }
    load();
  }, [userAddress]);

  return (
    <Stack direction="vertical" gap={1} className="py-3 px-1 w-70 align-self-center border-box">
      <Stack className="py-2 px-3" gap={2}>
        <h4>TCAP Points Program</h4>
        <Stack gap={2}>
          <p className="text-muted-light">
            Earn points to participate on the weekly CTX raffle. The more points you have, the more chances you have to win.
          </p>
          <ul>
            <li className="text-muted-light">
              Earn <span className="text-purple bold">1 point</span> with every <span className="text-purple bold">TCAP/WETH</span> swap greater than $500 total value.
            </li>
            <li className="text-muted-light">
              Earn <span className="text-purple bold">0.25 points</span> per day per <span className="text-purple bold">TCAP token</span>
              {" "}held in your account that was purchased directly from the <span className="text-purple bold"> Uniswap DEX pool</span>.
            </li>
          </ul>
        </Stack>
      </Stack>
      <Stack className="py-3 px-4" direction="vertical" gap={4}>
        <Stack direction="vertical" className="py-5" gap={1}>
          <span className="text-center" style={{ fontSize: "1.5rem" }}>
            Start earning points today!
          </span>
          <span className="text-purple text-center" style={{ fontSize: "1.5rem" }}>
            Leaderboard and First Raffle Coming Soon!
          </span>
        </Stack>
        {/* <Stack direction="horizontal" gap={2} className="px-3 py-2 border-box-secondary justify-content-between">
           <Stack direction="horizontal" gap={2} className="align-items-center">
            <Image src={GiftPic} height={26} width={26} />
            <p style={{ fontSize: "1.2rem", color: "#e1e0ec" }}>
              Congratulations you won <span className="text-purple">1000 CTX</span>
            </p>
          </Stack>
          <Stack direction="horizontal" className="justify-content-end">
            <Button style={{ width: "7rem" }}>
              Claim
            </Button>
          </Stack>
        </Stack>  */}
        {/* <Stack direction="vertical">
          <Stack direction="horizontal" className="px-3 mb-2">
            <Stack className="w-25">
              <span className="text-muted small">Rank</span>
            </Stack>
            <Stack className="w-45 text-muted">
              <span className="text-muted small">Address</span>
            </Stack>
            <Stack className="w-30 text-muted align-items-end">
              <span className="text-muted small">Points</span>
            </Stack>
          </Stack>
          <Stack className="vertical" gap={1}>
            {userAddress && <Stack key="you" direction="horizontal" className="px-3 py-3 border-box">
              <Stack className="w-25">
                <span className="text-purple zoom-2">{userPoins.rank > 0 ? userPoins.rank : "-"}</span>
              </Stack>
              <Stack className="w-45">
                <span className="text-purple zoom-2">You</span>
              </Stack>
              <Stack className="w-30 align-items-end">
                <span className="text-purple zoom-2">{userPoins.points}</span>
              </Stack>
            </Stack>}
            {leaderboard.map((entry, index) => {
              if (entry.address.toLowerCase() === userAddress?.toString().toLowerCase()) return null;

              return (
                <Stack
                  key={index}
                  direction="horizontal"
                  className={`px-3 py-3 ${index % 2 === 0 ? "border-box-light" : "border-box"}`}
                >
                  <Stack className="w-25">
                    <span>{entry.rank}</span>
                  </Stack>
                  <Stack className="w-45">
                    <span>
                      {!isMobile ? entry.address : shortenAddress(entry.address)}
                    </span>
                  </Stack>
                  <Stack className="w-30 align-items-end">
                    <span>{entry.total_amount}</span>
                  </Stack>
                </Stack>
              )
            })}
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  );
}
