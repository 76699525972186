import React, { useMemo } from 'react';
import { Dropdown, Image, Spinner, Stack } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { formatUnits } from 'viem';

import { useIndexVaultContext } from '../../../contexts/indexVaultContext';
import { IndexMetadata, IndexProducts, SupportedIndexes } from '../../../constants/indexes';


export const IndexTokenList = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { selectedIndex, setSelectedIndex, currentVaultSnapshot } = useIndexVaultContext();
  const indexMetadata = IndexMetadata[selectedIndex];

  const { indexPrice } = useMemo(() => { 
    if (!currentVaultSnapshot) return { indexPrice: "0" }

    return { indexPrice: parseFloat(formatUnits(currentVaultSnapshot.indexPrice, indexMetadata.decimals)).toFixed(4) }
  }, [indexMetadata.decimals, currentVaultSnapshot])

  return (
    <>
      <Dropdown
        className="markets-dropdown settokens"
        onSelect={(eventKey) => setSelectedIndex(eventKey as SupportedIndexes)}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          variant="success"
          disabled={true}
          className={isMobile ? "ps-0 pe-1" : "ps-1"}
        >
          <div className="dropdown-toggle-container ps-1">
            <div className="toggle-section market" style={{ width: "60%" }}>
              <Image src={indexMetadata.icon} height={36} width={36} />
              <div className={`market-desc ${isMobile ? "ps-1" : "ps-1"}`}>
                <h6 className="title" style={{ fontSize: "16px" }}>
                  {indexMetadata.symbol.toUpperCase()}
                </h6>
                <span className="caption" style={{ fontSize: "13px" }}>{indexMetadata.name}</span>
              </div>
            </div>
            <div className="vertical-divisor" />
            <div className="toggle-section price-box" style={{ width: !isMobile ? "40%" : "35%", fontSize: "1.2rem" }}>
              <div className="flex-column">
                {currentVaultSnapshot ? (
                  <span className={"price text-green"}>
                    {`$${indexPrice}`}
                  </span>
                ) : (
                  <Spinner animation="border" variant="success" />
                )}
              </div>
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(IndexMetadata).map((indexToken) => {
            const metada = IndexMetadata[indexToken as SupportedIndexes]
            if (!metada.products.includes(IndexProducts.vault)) return  <></>

            return (
              <Dropdown.Item key={`di-${indexToken}`}>
                <Stack direction="horizontal" gap={2}>
                  <Image src={metada.icon} height={38} width={38} />
                  <div className="market-desc">
                    <h6 className="title">
                      {metada.symbol}
                    </h6>
                    <span className="caption">{metada.name}</span>
                  </div>
                </Stack>  
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}